/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCompany = /* GraphQL */ `
  mutation CreateCompany(
    $input: CreateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    createCompany(input: $input, condition: $condition) {
      id
      companyName
      customerName
      customerEmail
      customerPhone
      customerAddress
      customerCity
      customerState
      customerZip
      serviceTax
      maxQuotableSize
      minQuotableSize
      autoPayAvailable
      prePayAvailable
      autoPayAlternativeLabel
      autoPayPriceMethod
      autoPayMonthlyPricePeriod
      bypassPayment
      crmSolution
      crmPortalUrl
      lawnbotEmployee
      marketingSource
      callStatusCode
      callActionReason
      crmNewRecordStatusScode
      crmQuoteStatusCode
      crmSaleStatusCode
      crmCompanyId
      crmRoute
      crmDayCode
      crmHandheld
      dashboardUsername
      dashboardGreetingName
      dashboardEmailAddress
      paymentType
      termsOfService
      createdAt
      updatedAt
      branding {
        id
        instaQuoteMessage
        companyLogo
        companyAvatar
        tagLine
        valueAddImage
        valueAddDescription
        aboutUsAssetUrl
        aboutUsAssetType
        quoteModalImage
        createdAt
        updatedAt
        company {
          id
          companyName
          customerName
          customerEmail
          customerPhone
          customerAddress
          customerCity
          customerState
          customerZip
          serviceTax
          maxQuotableSize
          minQuotableSize
          autoPayAvailable
          prePayAvailable
          autoPayAlternativeLabel
          autoPayPriceMethod
          autoPayMonthlyPricePeriod
          bypassPayment
          crmSolution
          crmPortalUrl
          lawnbotEmployee
          marketingSource
          callStatusCode
          callActionReason
          crmNewRecordStatusScode
          crmQuoteStatusCode
          crmSaleStatusCode
          crmCompanyId
          crmRoute
          crmDayCode
          crmHandheld
          dashboardUsername
          dashboardGreetingName
          dashboardEmailAddress
          paymentType
          termsOfService
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      bots {
        items {
          id
          botTitle
          botName
          botType
          botVersion
          botStatus
          isLive
          botQuoteAnalyzingGif
          sqFtPercentage
          sqftEstimateSource
          zillowFallback
          learnMore
          objections
          preModal
          serviceQuestions
          lookupZipCodeTax
          zipCodes
          dontTaxZipCodes
          botSpeed
          willRedirect
          redirectUrl
          skipProbingQuestions
          greeting
          salutation
          hideSummary
          isMetric
          measurementMode
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      programs {
        items {
          id
          programType
          programName
          programDescription
          programStartMonth
          programEndMonth
          usesUpfrontPricing
          usesGoogleSheetPricing
          googleSheetSheetId
          googleSheetTabName
          googleSheetRangeMapping
          googleSheetResultsMapping
          upfrontPricingTable
          probingQuestionsJson
          active
          isUpsell
          excludedZips
          productOffering
          conditions
          alwaysOnTop
          alwaysAvailable
          crmProgamCode
          seasonalText
          alternativeApplicationString
          useProbingQuestions
          useRealGreenPricingEngine
          realGreenPrepayDiscountCode
          realGreenApplicationDiscountCode
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      priceTables {
        items {
          id
          year
          name
          prePayDiscount
          prePayCutoff
          serviceType
          conditions
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateCompany = /* GraphQL */ `
  mutation UpdateCompany(
    $input: UpdateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    updateCompany(input: $input, condition: $condition) {
      id
      companyName
      customerName
      customerEmail
      customerPhone
      customerAddress
      customerCity
      customerState
      customerZip
      serviceTax
      maxQuotableSize
      minQuotableSize
      autoPayAvailable
      prePayAvailable
      autoPayAlternativeLabel
      autoPayPriceMethod
      autoPayMonthlyPricePeriod
      bypassPayment
      crmSolution
      crmPortalUrl
      lawnbotEmployee
      marketingSource
      callStatusCode
      callActionReason
      crmNewRecordStatusScode
      crmQuoteStatusCode
      crmSaleStatusCode
      crmCompanyId
      crmRoute
      crmDayCode
      crmHandheld
      dashboardUsername
      dashboardGreetingName
      dashboardEmailAddress
      paymentType
      termsOfService
      createdAt
      updatedAt
      branding {
        id
        instaQuoteMessage
        companyLogo
        companyAvatar
        tagLine
        valueAddImage
        valueAddDescription
        aboutUsAssetUrl
        aboutUsAssetType
        quoteModalImage
        createdAt
        updatedAt
        company {
          id
          companyName
          customerName
          customerEmail
          customerPhone
          customerAddress
          customerCity
          customerState
          customerZip
          serviceTax
          maxQuotableSize
          minQuotableSize
          autoPayAvailable
          prePayAvailable
          autoPayAlternativeLabel
          autoPayPriceMethod
          autoPayMonthlyPricePeriod
          bypassPayment
          crmSolution
          crmPortalUrl
          lawnbotEmployee
          marketingSource
          callStatusCode
          callActionReason
          crmNewRecordStatusScode
          crmQuoteStatusCode
          crmSaleStatusCode
          crmCompanyId
          crmRoute
          crmDayCode
          crmHandheld
          dashboardUsername
          dashboardGreetingName
          dashboardEmailAddress
          paymentType
          termsOfService
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      bots {
        items {
          id
          botTitle
          botName
          botType
          botVersion
          botStatus
          isLive
          botQuoteAnalyzingGif
          sqFtPercentage
          sqftEstimateSource
          zillowFallback
          learnMore
          objections
          preModal
          serviceQuestions
          lookupZipCodeTax
          zipCodes
          dontTaxZipCodes
          botSpeed
          willRedirect
          redirectUrl
          skipProbingQuestions
          greeting
          salutation
          hideSummary
          isMetric
          measurementMode
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      programs {
        items {
          id
          programType
          programName
          programDescription
          programStartMonth
          programEndMonth
          usesUpfrontPricing
          usesGoogleSheetPricing
          googleSheetSheetId
          googleSheetTabName
          googleSheetRangeMapping
          googleSheetResultsMapping
          upfrontPricingTable
          probingQuestionsJson
          active
          isUpsell
          excludedZips
          productOffering
          conditions
          alwaysOnTop
          alwaysAvailable
          crmProgamCode
          seasonalText
          alternativeApplicationString
          useProbingQuestions
          useRealGreenPricingEngine
          realGreenPrepayDiscountCode
          realGreenApplicationDiscountCode
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      priceTables {
        items {
          id
          year
          name
          prePayDiscount
          prePayCutoff
          serviceType
          conditions
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteCompany = /* GraphQL */ `
  mutation DeleteCompany(
    $input: DeleteCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    deleteCompany(input: $input, condition: $condition) {
      id
      companyName
      customerName
      customerEmail
      customerPhone
      customerAddress
      customerCity
      customerState
      customerZip
      serviceTax
      maxQuotableSize
      minQuotableSize
      autoPayAvailable
      prePayAvailable
      autoPayAlternativeLabel
      autoPayPriceMethod
      autoPayMonthlyPricePeriod
      bypassPayment
      crmSolution
      crmPortalUrl
      lawnbotEmployee
      marketingSource
      callStatusCode
      callActionReason
      crmNewRecordStatusScode
      crmQuoteStatusCode
      crmSaleStatusCode
      crmCompanyId
      crmRoute
      crmDayCode
      crmHandheld
      dashboardUsername
      dashboardGreetingName
      dashboardEmailAddress
      paymentType
      termsOfService
      createdAt
      updatedAt
      branding {
        id
        instaQuoteMessage
        companyLogo
        companyAvatar
        tagLine
        valueAddImage
        valueAddDescription
        aboutUsAssetUrl
        aboutUsAssetType
        quoteModalImage
        createdAt
        updatedAt
        company {
          id
          companyName
          customerName
          customerEmail
          customerPhone
          customerAddress
          customerCity
          customerState
          customerZip
          serviceTax
          maxQuotableSize
          minQuotableSize
          autoPayAvailable
          prePayAvailable
          autoPayAlternativeLabel
          autoPayPriceMethod
          autoPayMonthlyPricePeriod
          bypassPayment
          crmSolution
          crmPortalUrl
          lawnbotEmployee
          marketingSource
          callStatusCode
          callActionReason
          crmNewRecordStatusScode
          crmQuoteStatusCode
          crmSaleStatusCode
          crmCompanyId
          crmRoute
          crmDayCode
          crmHandheld
          dashboardUsername
          dashboardGreetingName
          dashboardEmailAddress
          paymentType
          termsOfService
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      bots {
        items {
          id
          botTitle
          botName
          botType
          botVersion
          botStatus
          isLive
          botQuoteAnalyzingGif
          sqFtPercentage
          sqftEstimateSource
          zillowFallback
          learnMore
          objections
          preModal
          serviceQuestions
          lookupZipCodeTax
          zipCodes
          dontTaxZipCodes
          botSpeed
          willRedirect
          redirectUrl
          skipProbingQuestions
          greeting
          salutation
          hideSummary
          isMetric
          measurementMode
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      programs {
        items {
          id
          programType
          programName
          programDescription
          programStartMonth
          programEndMonth
          usesUpfrontPricing
          usesGoogleSheetPricing
          googleSheetSheetId
          googleSheetTabName
          googleSheetRangeMapping
          googleSheetResultsMapping
          upfrontPricingTable
          probingQuestionsJson
          active
          isUpsell
          excludedZips
          productOffering
          conditions
          alwaysOnTop
          alwaysAvailable
          crmProgamCode
          seasonalText
          alternativeApplicationString
          useProbingQuestions
          useRealGreenPricingEngine
          realGreenPrepayDiscountCode
          realGreenApplicationDiscountCode
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      priceTables {
        items {
          id
          year
          name
          prePayDiscount
          prePayCutoff
          serviceType
          conditions
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createCompanyBranding = /* GraphQL */ `
  mutation CreateCompanyBranding(
    $input: CreateCompanyBrandingInput!
    $condition: ModelCompanyBrandingConditionInput
  ) {
    createCompanyBranding(input: $input, condition: $condition) {
      id
      instaQuoteMessage
      companyLogo
      companyAvatar
      tagLine
      valueAddImage
      valueAddDescription
      aboutUsAssetUrl
      aboutUsAssetType
      quoteModalImage
      createdAt
      updatedAt
      company {
        id
        companyName
        customerName
        customerEmail
        customerPhone
        customerAddress
        customerCity
        customerState
        customerZip
        serviceTax
        maxQuotableSize
        minQuotableSize
        autoPayAvailable
        prePayAvailable
        autoPayAlternativeLabel
        autoPayPriceMethod
        autoPayMonthlyPricePeriod
        bypassPayment
        crmSolution
        crmPortalUrl
        lawnbotEmployee
        marketingSource
        callStatusCode
        callActionReason
        crmNewRecordStatusScode
        crmQuoteStatusCode
        crmSaleStatusCode
        crmCompanyId
        crmRoute
        crmDayCode
        crmHandheld
        dashboardUsername
        dashboardGreetingName
        dashboardEmailAddress
        paymentType
        termsOfService
        createdAt
        updatedAt
        branding {
          id
          instaQuoteMessage
          companyLogo
          companyAvatar
          tagLine
          valueAddImage
          valueAddDescription
          aboutUsAssetUrl
          aboutUsAssetType
          quoteModalImage
          createdAt
          updatedAt
          __typename
        }
        bots {
          nextToken
          __typename
        }
        programs {
          nextToken
          __typename
        }
        priceTables {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const updateCompanyBranding = /* GraphQL */ `
  mutation UpdateCompanyBranding(
    $input: UpdateCompanyBrandingInput!
    $condition: ModelCompanyBrandingConditionInput
  ) {
    updateCompanyBranding(input: $input, condition: $condition) {
      id
      instaQuoteMessage
      companyLogo
      companyAvatar
      tagLine
      valueAddImage
      valueAddDescription
      aboutUsAssetUrl
      aboutUsAssetType
      quoteModalImage
      createdAt
      updatedAt
      company {
        id
        companyName
        customerName
        customerEmail
        customerPhone
        customerAddress
        customerCity
        customerState
        customerZip
        serviceTax
        maxQuotableSize
        minQuotableSize
        autoPayAvailable
        prePayAvailable
        autoPayAlternativeLabel
        autoPayPriceMethod
        autoPayMonthlyPricePeriod
        bypassPayment
        crmSolution
        crmPortalUrl
        lawnbotEmployee
        marketingSource
        callStatusCode
        callActionReason
        crmNewRecordStatusScode
        crmQuoteStatusCode
        crmSaleStatusCode
        crmCompanyId
        crmRoute
        crmDayCode
        crmHandheld
        dashboardUsername
        dashboardGreetingName
        dashboardEmailAddress
        paymentType
        termsOfService
        createdAt
        updatedAt
        branding {
          id
          instaQuoteMessage
          companyLogo
          companyAvatar
          tagLine
          valueAddImage
          valueAddDescription
          aboutUsAssetUrl
          aboutUsAssetType
          quoteModalImage
          createdAt
          updatedAt
          __typename
        }
        bots {
          nextToken
          __typename
        }
        programs {
          nextToken
          __typename
        }
        priceTables {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const deleteCompanyBranding = /* GraphQL */ `
  mutation DeleteCompanyBranding(
    $input: DeleteCompanyBrandingInput!
    $condition: ModelCompanyBrandingConditionInput
  ) {
    deleteCompanyBranding(input: $input, condition: $condition) {
      id
      instaQuoteMessage
      companyLogo
      companyAvatar
      tagLine
      valueAddImage
      valueAddDescription
      aboutUsAssetUrl
      aboutUsAssetType
      quoteModalImage
      createdAt
      updatedAt
      company {
        id
        companyName
        customerName
        customerEmail
        customerPhone
        customerAddress
        customerCity
        customerState
        customerZip
        serviceTax
        maxQuotableSize
        minQuotableSize
        autoPayAvailable
        prePayAvailable
        autoPayAlternativeLabel
        autoPayPriceMethod
        autoPayMonthlyPricePeriod
        bypassPayment
        crmSolution
        crmPortalUrl
        lawnbotEmployee
        marketingSource
        callStatusCode
        callActionReason
        crmNewRecordStatusScode
        crmQuoteStatusCode
        crmSaleStatusCode
        crmCompanyId
        crmRoute
        crmDayCode
        crmHandheld
        dashboardUsername
        dashboardGreetingName
        dashboardEmailAddress
        paymentType
        termsOfService
        createdAt
        updatedAt
        branding {
          id
          instaQuoteMessage
          companyLogo
          companyAvatar
          tagLine
          valueAddImage
          valueAddDescription
          aboutUsAssetUrl
          aboutUsAssetType
          quoteModalImage
          createdAt
          updatedAt
          __typename
        }
        bots {
          nextToken
          __typename
        }
        programs {
          nextToken
          __typename
        }
        priceTables {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const createBot = /* GraphQL */ `
  mutation CreateBot(
    $input: CreateBotInput!
    $condition: ModelBotConditionInput
  ) {
    createBot(input: $input, condition: $condition) {
      id
      botTitle
      botName
      botType
      botVersion
      botStatus
      isLive
      botQuoteAnalyzingGif
      sqFtPercentage
      sqftEstimateSource
      zillowFallback
      learnMore
      objections
      preModal
      serviceQuestions
      lookupZipCodeTax
      zipCodes
      dontTaxZipCodes
      botSpeed
      willRedirect
      redirectUrl
      skipProbingQuestions
      greeting
      salutation
      hideSummary
      isMetric
      measurementMode
      createdAt
      updatedAt
      company {
        id
        companyName
        customerName
        customerEmail
        customerPhone
        customerAddress
        customerCity
        customerState
        customerZip
        serviceTax
        maxQuotableSize
        minQuotableSize
        autoPayAvailable
        prePayAvailable
        autoPayAlternativeLabel
        autoPayPriceMethod
        autoPayMonthlyPricePeriod
        bypassPayment
        crmSolution
        crmPortalUrl
        lawnbotEmployee
        marketingSource
        callStatusCode
        callActionReason
        crmNewRecordStatusScode
        crmQuoteStatusCode
        crmSaleStatusCode
        crmCompanyId
        crmRoute
        crmDayCode
        crmHandheld
        dashboardUsername
        dashboardGreetingName
        dashboardEmailAddress
        paymentType
        termsOfService
        createdAt
        updatedAt
        branding {
          id
          instaQuoteMessage
          companyLogo
          companyAvatar
          tagLine
          valueAddImage
          valueAddDescription
          aboutUsAssetUrl
          aboutUsAssetType
          quoteModalImage
          createdAt
          updatedAt
          __typename
        }
        bots {
          nextToken
          __typename
        }
        programs {
          nextToken
          __typename
        }
        priceTables {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const updateBot = /* GraphQL */ `
  mutation UpdateBot(
    $input: UpdateBotInput!
    $condition: ModelBotConditionInput
  ) {
    updateBot(input: $input, condition: $condition) {
      id
      botTitle
      botName
      botType
      botVersion
      botStatus
      isLive
      botQuoteAnalyzingGif
      sqFtPercentage
      sqftEstimateSource
      zillowFallback
      learnMore
      objections
      preModal
      serviceQuestions
      lookupZipCodeTax
      zipCodes
      dontTaxZipCodes
      botSpeed
      willRedirect
      redirectUrl
      skipProbingQuestions
      greeting
      salutation
      hideSummary
      isMetric
      measurementMode
      createdAt
      updatedAt
      company {
        id
        companyName
        customerName
        customerEmail
        customerPhone
        customerAddress
        customerCity
        customerState
        customerZip
        serviceTax
        maxQuotableSize
        minQuotableSize
        autoPayAvailable
        prePayAvailable
        autoPayAlternativeLabel
        autoPayPriceMethod
        autoPayMonthlyPricePeriod
        bypassPayment
        crmSolution
        crmPortalUrl
        lawnbotEmployee
        marketingSource
        callStatusCode
        callActionReason
        crmNewRecordStatusScode
        crmQuoteStatusCode
        crmSaleStatusCode
        crmCompanyId
        crmRoute
        crmDayCode
        crmHandheld
        dashboardUsername
        dashboardGreetingName
        dashboardEmailAddress
        paymentType
        termsOfService
        createdAt
        updatedAt
        branding {
          id
          instaQuoteMessage
          companyLogo
          companyAvatar
          tagLine
          valueAddImage
          valueAddDescription
          aboutUsAssetUrl
          aboutUsAssetType
          quoteModalImage
          createdAt
          updatedAt
          __typename
        }
        bots {
          nextToken
          __typename
        }
        programs {
          nextToken
          __typename
        }
        priceTables {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const deleteBot = /* GraphQL */ `
  mutation DeleteBot(
    $input: DeleteBotInput!
    $condition: ModelBotConditionInput
  ) {
    deleteBot(input: $input, condition: $condition) {
      id
      botTitle
      botName
      botType
      botVersion
      botStatus
      isLive
      botQuoteAnalyzingGif
      sqFtPercentage
      sqftEstimateSource
      zillowFallback
      learnMore
      objections
      preModal
      serviceQuestions
      lookupZipCodeTax
      zipCodes
      dontTaxZipCodes
      botSpeed
      willRedirect
      redirectUrl
      skipProbingQuestions
      greeting
      salutation
      hideSummary
      isMetric
      measurementMode
      createdAt
      updatedAt
      company {
        id
        companyName
        customerName
        customerEmail
        customerPhone
        customerAddress
        customerCity
        customerState
        customerZip
        serviceTax
        maxQuotableSize
        minQuotableSize
        autoPayAvailable
        prePayAvailable
        autoPayAlternativeLabel
        autoPayPriceMethod
        autoPayMonthlyPricePeriod
        bypassPayment
        crmSolution
        crmPortalUrl
        lawnbotEmployee
        marketingSource
        callStatusCode
        callActionReason
        crmNewRecordStatusScode
        crmQuoteStatusCode
        crmSaleStatusCode
        crmCompanyId
        crmRoute
        crmDayCode
        crmHandheld
        dashboardUsername
        dashboardGreetingName
        dashboardEmailAddress
        paymentType
        termsOfService
        createdAt
        updatedAt
        branding {
          id
          instaQuoteMessage
          companyLogo
          companyAvatar
          tagLine
          valueAddImage
          valueAddDescription
          aboutUsAssetUrl
          aboutUsAssetType
          quoteModalImage
          createdAt
          updatedAt
          __typename
        }
        bots {
          nextToken
          __typename
        }
        programs {
          nextToken
          __typename
        }
        priceTables {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const createSection = /* GraphQL */ `
  mutation CreateSection(
    $input: CreateSectionInput!
    $condition: ModelSectionConditionInput
  ) {
    createSection(input: $input, condition: $condition) {
      id
      sectionName
      entryId
      nextSectionId
      createdAt
      updatedAt
      conditionals {
        items {
          id
          matches
          trigger
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      steps {
        items {
          id
          stepName
          optionType
          message
          end
          asMessage
          waitAction
          trigger
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      bot {
        version
        id
        botCompanyId
        botTitle
        botName
        botType
        botVersion
        botStatus
        isLive
        botQuoteAnalyzingGif
        sqFtPercentage
        sqftEstimateSource
        zillowFallback
        learnMore
        objections
        preModal
        serviceQuestions
        lookupZipCodeTax
        zipCodes
        dontTaxZipCodes
        botSpeed
        willRedirect
        redirectUrl
        skipProbingQuestions
        greeting
        salutation
        hideSummary
        isMetric
        measurementMode
        hideArielAndStreetView
        customSchedulingVerbiage
        forceKey
        createdAt
        updatedAt
        sections {
          nextToken
          __typename
        }
        company {
          version
          id
          approved
          approvedBy
          approvedOn
          companyName
          customerName
          customerEmail
          customerPhone
          customerAddress
          customerCity
          customerState
          customerZip
          companyBrandingId
          companyDataServicesId
          currency
          serviceTax
          maxQuotableSize
          minQuotableSize
          autoPayAvailable
          prePayAvailable
          autoPayAlternativeLabel
          autoPayPriceMethod
          taxOverride
          autoPayMonthlyPricePeriod
          bypassPayment
          crmSolution
          crmPortalUrl
          lawnbotEmployee
          marketingSource
          callStatusCode
          callActionReason
          crmNewRecordStatusScode
          crmQuoteStatusCode
          crmSaleStatusCode
          pestPacTenantId
          crmCompanyId
          crmRoute
          crmDayCode
          crmHandheld
          dashboardUsername
          dashboardGreetingName
          dashboardEmailAddress
          paymentType
          termsOfService
          onboardingJson
          serviceAgreementContent
          noServiceAgreementContent
          serviceAgreementTellMeMore
          emergencyTellMeMore
          gettingStartedText
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const updateSection = /* GraphQL */ `
  mutation UpdateSection(
    $input: UpdateSectionInput!
    $condition: ModelSectionConditionInput
  ) {
    updateSection(input: $input, condition: $condition) {
      id
      sectionName
      entryId
      nextSectionId
      createdAt
      updatedAt
      conditionals {
        items {
          id
          matches
          trigger
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      steps {
        items {
          id
          stepName
          optionType
          message
          end
          asMessage
          waitAction
          trigger
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      bot {
        version
        id
        botCompanyId
        botTitle
        botName
        botType
        botVersion
        botStatus
        isLive
        botQuoteAnalyzingGif
        sqFtPercentage
        sqftEstimateSource
        zillowFallback
        learnMore
        objections
        preModal
        serviceQuestions
        lookupZipCodeTax
        zipCodes
        dontTaxZipCodes
        botSpeed
        willRedirect
        redirectUrl
        skipProbingQuestions
        greeting
        salutation
        hideSummary
        isMetric
        measurementMode
        hideArielAndStreetView
        customSchedulingVerbiage
        forceKey
        createdAt
        updatedAt
        sections {
          nextToken
          __typename
        }
        company {
          version
          id
          approved
          approvedBy
          approvedOn
          companyName
          customerName
          customerEmail
          customerPhone
          customerAddress
          customerCity
          customerState
          customerZip
          companyBrandingId
          companyDataServicesId
          currency
          serviceTax
          maxQuotableSize
          minQuotableSize
          autoPayAvailable
          prePayAvailable
          autoPayAlternativeLabel
          autoPayPriceMethod
          taxOverride
          autoPayMonthlyPricePeriod
          bypassPayment
          crmSolution
          crmPortalUrl
          lawnbotEmployee
          marketingSource
          callStatusCode
          callActionReason
          crmNewRecordStatusScode
          crmQuoteStatusCode
          crmSaleStatusCode
          pestPacTenantId
          crmCompanyId
          crmRoute
          crmDayCode
          crmHandheld
          dashboardUsername
          dashboardGreetingName
          dashboardEmailAddress
          paymentType
          termsOfService
          onboardingJson
          serviceAgreementContent
          noServiceAgreementContent
          serviceAgreementTellMeMore
          emergencyTellMeMore
          gettingStartedText
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const deleteSection = /* GraphQL */ `
  mutation DeleteSection(
    $input: DeleteSectionInput!
    $condition: ModelSectionConditionInput
  ) {
    deleteSection(input: $input, condition: $condition) {
      id
      sectionName
      entryId
      nextSectionId
      createdAt
      updatedAt
      conditionals {
        items {
          id
          matches
          trigger
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      steps {
        items {
          id
          stepName
          optionType
          message
          end
          asMessage
          waitAction
          trigger
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      bot {
        version
        id
        botCompanyId
        botTitle
        botName
        botType
        botVersion
        botStatus
        isLive
        botQuoteAnalyzingGif
        sqFtPercentage
        sqftEstimateSource
        zillowFallback
        learnMore
        objections
        preModal
        serviceQuestions
        lookupZipCodeTax
        zipCodes
        dontTaxZipCodes
        botSpeed
        willRedirect
        redirectUrl
        skipProbingQuestions
        greeting
        salutation
        hideSummary
        isMetric
        measurementMode
        hideArielAndStreetView
        customSchedulingVerbiage
        forceKey
        createdAt
        updatedAt
        sections {
          nextToken
          __typename
        }
        company {
          version
          id
          approved
          approvedBy
          approvedOn
          companyName
          customerName
          customerEmail
          customerPhone
          customerAddress
          customerCity
          customerState
          customerZip
          companyBrandingId
          companyDataServicesId
          currency
          serviceTax
          maxQuotableSize
          minQuotableSize
          autoPayAvailable
          prePayAvailable
          autoPayAlternativeLabel
          autoPayPriceMethod
          taxOverride
          autoPayMonthlyPricePeriod
          bypassPayment
          crmSolution
          crmPortalUrl
          lawnbotEmployee
          marketingSource
          callStatusCode
          callActionReason
          crmNewRecordStatusScode
          crmQuoteStatusCode
          crmSaleStatusCode
          pestPacTenantId
          crmCompanyId
          crmRoute
          crmDayCode
          crmHandheld
          dashboardUsername
          dashboardGreetingName
          dashboardEmailAddress
          paymentType
          termsOfService
          onboardingJson
          serviceAgreementContent
          noServiceAgreementContent
          serviceAgreementTellMeMore
          emergencyTellMeMore
          gettingStartedText
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const createProbingQuestions = /* GraphQL */ `
  mutation CreateProbingQuestions(
    $input: CreateProbingQuestionsInput!
    $condition: ModelProbingQuestionsConditionInput
  ) {
    createProbingQuestions(input: $input, condition: $condition) {
      id
      probingQuestionsProgramId
      sectionName
      entryId
      nextSectionId
      steps {
        id
        stepName
        optionType
        message
        end
        asMessage
        waitAction
        trigger
        createdAt
        updatedAt
        section {
          id
          sectionName
          entryId
          nextSectionId
          createdAt
          updatedAt
          __typename
        }
        options {
          nextToken
          __typename
        }
        component {
          id
          componentName
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      conditionals {
        id
        matches
        trigger
        createdAt
        updatedAt
        section {
          id
          sectionName
          entryId
          nextSectionId
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      program {
        version
        id
        programCompanyId
        isDeleted
        programType
        programName
        programDescription
        programStartMonth
        programEndMonth
        hideDiscountText
        programProbingQuestionsId
        probingQuestionsJson
        active
        isUpsell
        excludedZips
        productOffering
        conditions
        alwaysOnTop
        alwaysAvailable
        crmProgamCode
        seasonalText
        usesUpfrontPricing
        usesGoogleSheetPricing
        googleSheetSheetId
        googleSheetTabName
        googleSheetRangeMapping
        googleSheetResultsMapping
        upfrontPricingTable
        recurringPricingTable
        alternativeApplicationString
        useProbingQuestions
        useRealGreenPricingEngine
        realGreenPrepayDiscountCode
        realGreenApplicationDiscountCode
        createdAt
        updatedAt
        probingQuestions {
          id
          probingQuestionsProgramId
          sectionName
          entryId
          nextSectionId
          createdAt
          updatedAt
          __typename
        }
        company {
          version
          id
          approved
          approvedBy
          approvedOn
          companyName
          customerName
          customerEmail
          customerPhone
          customerAddress
          customerCity
          customerState
          customerZip
          companyBrandingId
          companyDataServicesId
          currency
          serviceTax
          maxQuotableSize
          minQuotableSize
          autoPayAvailable
          prePayAvailable
          autoPayAlternativeLabel
          autoPayPriceMethod
          taxOverride
          autoPayMonthlyPricePeriod
          bypassPayment
          crmSolution
          crmPortalUrl
          lawnbotEmployee
          marketingSource
          callStatusCode
          callActionReason
          crmNewRecordStatusScode
          crmQuoteStatusCode
          crmSaleStatusCode
          pestPacTenantId
          crmCompanyId
          crmRoute
          crmDayCode
          crmHandheld
          dashboardUsername
          dashboardGreetingName
          dashboardEmailAddress
          paymentType
          termsOfService
          onboardingJson
          serviceAgreementContent
          noServiceAgreementContent
          serviceAgreementTellMeMore
          emergencyTellMeMore
          gettingStartedText
          createdAt
          updatedAt
          __typename
        }
        services {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const updateProbingQuestions = /* GraphQL */ `
  mutation UpdateProbingQuestions(
    $input: UpdateProbingQuestionsInput!
    $condition: ModelProbingQuestionsConditionInput
  ) {
    updateProbingQuestions(input: $input, condition: $condition) {
      id
      probingQuestionsProgramId
      sectionName
      entryId
      nextSectionId
      steps {
        id
        stepName
        optionType
        message
        end
        asMessage
        waitAction
        trigger
        createdAt
        updatedAt
        section {
          id
          sectionName
          entryId
          nextSectionId
          createdAt
          updatedAt
          __typename
        }
        options {
          nextToken
          __typename
        }
        component {
          id
          componentName
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      conditionals {
        id
        matches
        trigger
        createdAt
        updatedAt
        section {
          id
          sectionName
          entryId
          nextSectionId
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      program {
        version
        id
        programCompanyId
        isDeleted
        programType
        programName
        programDescription
        programStartMonth
        programEndMonth
        hideDiscountText
        programProbingQuestionsId
        probingQuestionsJson
        active
        isUpsell
        excludedZips
        productOffering
        conditions
        alwaysOnTop
        alwaysAvailable
        crmProgamCode
        seasonalText
        usesUpfrontPricing
        usesGoogleSheetPricing
        googleSheetSheetId
        googleSheetTabName
        googleSheetRangeMapping
        googleSheetResultsMapping
        upfrontPricingTable
        recurringPricingTable
        alternativeApplicationString
        useProbingQuestions
        useRealGreenPricingEngine
        realGreenPrepayDiscountCode
        realGreenApplicationDiscountCode
        createdAt
        updatedAt
        probingQuestions {
          id
          probingQuestionsProgramId
          sectionName
          entryId
          nextSectionId
          createdAt
          updatedAt
          __typename
        }
        company {
          version
          id
          approved
          approvedBy
          approvedOn
          companyName
          customerName
          customerEmail
          customerPhone
          customerAddress
          customerCity
          customerState
          customerZip
          companyBrandingId
          companyDataServicesId
          currency
          serviceTax
          maxQuotableSize
          minQuotableSize
          autoPayAvailable
          prePayAvailable
          autoPayAlternativeLabel
          autoPayPriceMethod
          taxOverride
          autoPayMonthlyPricePeriod
          bypassPayment
          crmSolution
          crmPortalUrl
          lawnbotEmployee
          marketingSource
          callStatusCode
          callActionReason
          crmNewRecordStatusScode
          crmQuoteStatusCode
          crmSaleStatusCode
          pestPacTenantId
          crmCompanyId
          crmRoute
          crmDayCode
          crmHandheld
          dashboardUsername
          dashboardGreetingName
          dashboardEmailAddress
          paymentType
          termsOfService
          onboardingJson
          serviceAgreementContent
          noServiceAgreementContent
          serviceAgreementTellMeMore
          emergencyTellMeMore
          gettingStartedText
          createdAt
          updatedAt
          __typename
        }
        services {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const deleteProbingQuestions = /* GraphQL */ `
  mutation DeleteProbingQuestions(
    $input: DeleteProbingQuestionsInput!
    $condition: ModelProbingQuestionsConditionInput
  ) {
    deleteProbingQuestions(input: $input, condition: $condition) {
      id
      probingQuestionsProgramId
      sectionName
      entryId
      nextSectionId
      steps {
        id
        stepName
        optionType
        message
        end
        asMessage
        waitAction
        trigger
        createdAt
        updatedAt
        section {
          id
          sectionName
          entryId
          nextSectionId
          createdAt
          updatedAt
          __typename
        }
        options {
          nextToken
          __typename
        }
        component {
          id
          componentName
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      conditionals {
        id
        matches
        trigger
        createdAt
        updatedAt
        section {
          id
          sectionName
          entryId
          nextSectionId
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      program {
        version
        id
        programCompanyId
        isDeleted
        programType
        programName
        programDescription
        programStartMonth
        programEndMonth
        hideDiscountText
        programProbingQuestionsId
        probingQuestionsJson
        active
        isUpsell
        excludedZips
        productOffering
        conditions
        alwaysOnTop
        alwaysAvailable
        crmProgamCode
        seasonalText
        usesUpfrontPricing
        usesGoogleSheetPricing
        googleSheetSheetId
        googleSheetTabName
        googleSheetRangeMapping
        googleSheetResultsMapping
        upfrontPricingTable
        recurringPricingTable
        alternativeApplicationString
        useProbingQuestions
        useRealGreenPricingEngine
        realGreenPrepayDiscountCode
        realGreenApplicationDiscountCode
        createdAt
        updatedAt
        probingQuestions {
          id
          probingQuestionsProgramId
          sectionName
          entryId
          nextSectionId
          createdAt
          updatedAt
          __typename
        }
        company {
          version
          id
          approved
          approvedBy
          approvedOn
          companyName
          customerName
          customerEmail
          customerPhone
          customerAddress
          customerCity
          customerState
          customerZip
          companyBrandingId
          companyDataServicesId
          currency
          serviceTax
          maxQuotableSize
          minQuotableSize
          autoPayAvailable
          prePayAvailable
          autoPayAlternativeLabel
          autoPayPriceMethod
          taxOverride
          autoPayMonthlyPricePeriod
          bypassPayment
          crmSolution
          crmPortalUrl
          lawnbotEmployee
          marketingSource
          callStatusCode
          callActionReason
          crmNewRecordStatusScode
          crmQuoteStatusCode
          crmSaleStatusCode
          pestPacTenantId
          crmCompanyId
          crmRoute
          crmDayCode
          crmHandheld
          dashboardUsername
          dashboardGreetingName
          dashboardEmailAddress
          paymentType
          termsOfService
          onboardingJson
          serviceAgreementContent
          noServiceAgreementContent
          serviceAgreementTellMeMore
          emergencyTellMeMore
          gettingStartedText
          createdAt
          updatedAt
          __typename
        }
        services {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const createConditional = /* GraphQL */ `
  mutation CreateConditional(
    $input: CreateConditionalInput!
    $condition: ModelConditionalConditionInput
  ) {
    createConditional(input: $input, condition: $condition) {
      id
      matches
      trigger
      createdAt
      updatedAt
      section {
        id
        sectionName
        entryId
        nextSectionId
        createdAt
        updatedAt
        conditionals {
          nextToken
          __typename
        }
        steps {
          nextToken
          __typename
        }
        bot {
          version
          id
          botCompanyId
          botTitle
          botName
          botType
          botVersion
          botStatus
          isLive
          botQuoteAnalyzingGif
          sqFtPercentage
          sqftEstimateSource
          zillowFallback
          learnMore
          objections
          preModal
          serviceQuestions
          lookupZipCodeTax
          zipCodes
          dontTaxZipCodes
          botSpeed
          willRedirect
          redirectUrl
          skipProbingQuestions
          greeting
          salutation
          hideSummary
          isMetric
          measurementMode
          hideArielAndStreetView
          customSchedulingVerbiage
          forceKey
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const updateConditional = /* GraphQL */ `
  mutation UpdateConditional(
    $input: UpdateConditionalInput!
    $condition: ModelConditionalConditionInput
  ) {
    updateConditional(input: $input, condition: $condition) {
      id
      matches
      trigger
      createdAt
      updatedAt
      section {
        id
        sectionName
        entryId
        nextSectionId
        createdAt
        updatedAt
        conditionals {
          nextToken
          __typename
        }
        steps {
          nextToken
          __typename
        }
        bot {
          version
          id
          botCompanyId
          botTitle
          botName
          botType
          botVersion
          botStatus
          isLive
          botQuoteAnalyzingGif
          sqFtPercentage
          sqftEstimateSource
          zillowFallback
          learnMore
          objections
          preModal
          serviceQuestions
          lookupZipCodeTax
          zipCodes
          dontTaxZipCodes
          botSpeed
          willRedirect
          redirectUrl
          skipProbingQuestions
          greeting
          salutation
          hideSummary
          isMetric
          measurementMode
          hideArielAndStreetView
          customSchedulingVerbiage
          forceKey
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const deleteConditional = /* GraphQL */ `
  mutation DeleteConditional(
    $input: DeleteConditionalInput!
    $condition: ModelConditionalConditionInput
  ) {
    deleteConditional(input: $input, condition: $condition) {
      id
      matches
      trigger
      createdAt
      updatedAt
      section {
        id
        sectionName
        entryId
        nextSectionId
        createdAt
        updatedAt
        conditionals {
          nextToken
          __typename
        }
        steps {
          nextToken
          __typename
        }
        bot {
          version
          id
          botCompanyId
          botTitle
          botName
          botType
          botVersion
          botStatus
          isLive
          botQuoteAnalyzingGif
          sqFtPercentage
          sqftEstimateSource
          zillowFallback
          learnMore
          objections
          preModal
          serviceQuestions
          lookupZipCodeTax
          zipCodes
          dontTaxZipCodes
          botSpeed
          willRedirect
          redirectUrl
          skipProbingQuestions
          greeting
          salutation
          hideSummary
          isMetric
          measurementMode
          hideArielAndStreetView
          customSchedulingVerbiage
          forceKey
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const createStep = /* GraphQL */ `
  mutation CreateStep(
    $input: CreateStepInput!
    $condition: ModelStepConditionInput
  ) {
    createStep(input: $input, condition: $condition) {
      id
      stepName
      optionType
      message
      end
      asMessage
      waitAction
      trigger
      createdAt
      updatedAt
      section {
        id
        sectionName
        entryId
        nextSectionId
        createdAt
        updatedAt
        conditionals {
          nextToken
          __typename
        }
        steps {
          nextToken
          __typename
        }
        bot {
          version
          id
          botCompanyId
          botTitle
          botName
          botType
          botVersion
          botStatus
          isLive
          botQuoteAnalyzingGif
          sqFtPercentage
          sqftEstimateSource
          zillowFallback
          learnMore
          objections
          preModal
          serviceQuestions
          lookupZipCodeTax
          zipCodes
          dontTaxZipCodes
          botSpeed
          willRedirect
          redirectUrl
          skipProbingQuestions
          greeting
          salutation
          hideSummary
          isMetric
          measurementMode
          hideArielAndStreetView
          customSchedulingVerbiage
          forceKey
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      options {
        items {
          id
          value
          label
          iconClass
          color
          image
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      component {
        id
        componentName
        createdAt
        updatedAt
        step {
          id
          stepName
          optionType
          message
          end
          asMessage
          waitAction
          trigger
          createdAt
          updatedAt
          __typename
        }
        props {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const updateStep = /* GraphQL */ `
  mutation UpdateStep(
    $input: UpdateStepInput!
    $condition: ModelStepConditionInput
  ) {
    updateStep(input: $input, condition: $condition) {
      id
      stepName
      optionType
      message
      end
      asMessage
      waitAction
      trigger
      createdAt
      updatedAt
      section {
        id
        sectionName
        entryId
        nextSectionId
        createdAt
        updatedAt
        conditionals {
          nextToken
          __typename
        }
        steps {
          nextToken
          __typename
        }
        bot {
          version
          id
          botCompanyId
          botTitle
          botName
          botType
          botVersion
          botStatus
          isLive
          botQuoteAnalyzingGif
          sqFtPercentage
          sqftEstimateSource
          zillowFallback
          learnMore
          objections
          preModal
          serviceQuestions
          lookupZipCodeTax
          zipCodes
          dontTaxZipCodes
          botSpeed
          willRedirect
          redirectUrl
          skipProbingQuestions
          greeting
          salutation
          hideSummary
          isMetric
          measurementMode
          hideArielAndStreetView
          customSchedulingVerbiage
          forceKey
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      options {
        items {
          id
          value
          label
          iconClass
          color
          image
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      component {
        id
        componentName
        createdAt
        updatedAt
        step {
          id
          stepName
          optionType
          message
          end
          asMessage
          waitAction
          trigger
          createdAt
          updatedAt
          __typename
        }
        props {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const deleteStep = /* GraphQL */ `
  mutation DeleteStep(
    $input: DeleteStepInput!
    $condition: ModelStepConditionInput
  ) {
    deleteStep(input: $input, condition: $condition) {
      id
      stepName
      optionType
      message
      end
      asMessage
      waitAction
      trigger
      createdAt
      updatedAt
      section {
        id
        sectionName
        entryId
        nextSectionId
        createdAt
        updatedAt
        conditionals {
          nextToken
          __typename
        }
        steps {
          nextToken
          __typename
        }
        bot {
          version
          id
          botCompanyId
          botTitle
          botName
          botType
          botVersion
          botStatus
          isLive
          botQuoteAnalyzingGif
          sqFtPercentage
          sqftEstimateSource
          zillowFallback
          learnMore
          objections
          preModal
          serviceQuestions
          lookupZipCodeTax
          zipCodes
          dontTaxZipCodes
          botSpeed
          willRedirect
          redirectUrl
          skipProbingQuestions
          greeting
          salutation
          hideSummary
          isMetric
          measurementMode
          hideArielAndStreetView
          customSchedulingVerbiage
          forceKey
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      options {
        items {
          id
          value
          label
          iconClass
          color
          image
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      component {
        id
        componentName
        createdAt
        updatedAt
        step {
          id
          stepName
          optionType
          message
          end
          asMessage
          waitAction
          trigger
          createdAt
          updatedAt
          __typename
        }
        props {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const createOption = /* GraphQL */ `
  mutation CreateOption(
    $input: CreateOptionInput!
    $condition: ModelOptionConditionInput
  ) {
    createOption(input: $input, condition: $condition) {
      id
      value
      label
      iconClass
      color
      image
      createdAt
      updatedAt
      step {
        id
        stepName
        optionType
        message
        end
        asMessage
        waitAction
        trigger
        createdAt
        updatedAt
        section {
          id
          sectionName
          entryId
          nextSectionId
          createdAt
          updatedAt
          __typename
        }
        options {
          nextToken
          __typename
        }
        component {
          id
          componentName
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const updateOption = /* GraphQL */ `
  mutation UpdateOption(
    $input: UpdateOptionInput!
    $condition: ModelOptionConditionInput
  ) {
    updateOption(input: $input, condition: $condition) {
      id
      value
      label
      iconClass
      color
      image
      createdAt
      updatedAt
      step {
        id
        stepName
        optionType
        message
        end
        asMessage
        waitAction
        trigger
        createdAt
        updatedAt
        section {
          id
          sectionName
          entryId
          nextSectionId
          createdAt
          updatedAt
          __typename
        }
        options {
          nextToken
          __typename
        }
        component {
          id
          componentName
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const deleteOption = /* GraphQL */ `
  mutation DeleteOption(
    $input: DeleteOptionInput!
    $condition: ModelOptionConditionInput
  ) {
    deleteOption(input: $input, condition: $condition) {
      id
      value
      label
      iconClass
      color
      image
      createdAt
      updatedAt
      step {
        id
        stepName
        optionType
        message
        end
        asMessage
        waitAction
        trigger
        createdAt
        updatedAt
        section {
          id
          sectionName
          entryId
          nextSectionId
          createdAt
          updatedAt
          __typename
        }
        options {
          nextToken
          __typename
        }
        component {
          id
          componentName
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const createComponent = /* GraphQL */ `
  mutation CreateComponent(
    $input: CreateComponentInput!
    $condition: ModelComponentConditionInput
  ) {
    createComponent(input: $input, condition: $condition) {
      id
      componentName
      createdAt
      updatedAt
      step {
        id
        stepName
        optionType
        message
        end
        asMessage
        waitAction
        trigger
        createdAt
        updatedAt
        section {
          id
          sectionName
          entryId
          nextSectionId
          createdAt
          updatedAt
          __typename
        }
        options {
          nextToken
          __typename
        }
        component {
          id
          componentName
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      props {
        items {
          id
          key
          value
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateComponent = /* GraphQL */ `
  mutation UpdateComponent(
    $input: UpdateComponentInput!
    $condition: ModelComponentConditionInput
  ) {
    updateComponent(input: $input, condition: $condition) {
      id
      componentName
      createdAt
      updatedAt
      step {
        id
        stepName
        optionType
        message
        end
        asMessage
        waitAction
        trigger
        createdAt
        updatedAt
        section {
          id
          sectionName
          entryId
          nextSectionId
          createdAt
          updatedAt
          __typename
        }
        options {
          nextToken
          __typename
        }
        component {
          id
          componentName
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      props {
        items {
          id
          key
          value
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteComponent = /* GraphQL */ `
  mutation DeleteComponent(
    $input: DeleteComponentInput!
    $condition: ModelComponentConditionInput
  ) {
    deleteComponent(input: $input, condition: $condition) {
      id
      componentName
      createdAt
      updatedAt
      step {
        id
        stepName
        optionType
        message
        end
        asMessage
        waitAction
        trigger
        createdAt
        updatedAt
        section {
          id
          sectionName
          entryId
          nextSectionId
          createdAt
          updatedAt
          __typename
        }
        options {
          nextToken
          __typename
        }
        component {
          id
          componentName
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      props {
        items {
          id
          key
          value
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createProp = /* GraphQL */ `
  mutation CreateProp(
    $input: CreatePropInput!
    $condition: ModelPropConditionInput
  ) {
    createProp(input: $input, condition: $condition) {
      id
      key
      value
      createdAt
      updatedAt
      component {
        id
        componentName
        createdAt
        updatedAt
        step {
          id
          stepName
          optionType
          message
          end
          asMessage
          waitAction
          trigger
          createdAt
          updatedAt
          __typename
        }
        props {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const updateProp = /* GraphQL */ `
  mutation UpdateProp(
    $input: UpdatePropInput!
    $condition: ModelPropConditionInput
  ) {
    updateProp(input: $input, condition: $condition) {
      id
      key
      value
      createdAt
      updatedAt
      component {
        id
        componentName
        createdAt
        updatedAt
        step {
          id
          stepName
          optionType
          message
          end
          asMessage
          waitAction
          trigger
          createdAt
          updatedAt
          __typename
        }
        props {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const deleteProp = /* GraphQL */ `
  mutation DeleteProp(
    $input: DeletePropInput!
    $condition: ModelPropConditionInput
  ) {
    deleteProp(input: $input, condition: $condition) {
      id
      key
      value
      createdAt
      updatedAt
      component {
        id
        componentName
        createdAt
        updatedAt
        step {
          id
          stepName
          optionType
          message
          end
          asMessage
          waitAction
          trigger
          createdAt
          updatedAt
          __typename
        }
        props {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const createProgram = /* GraphQL */ `
  mutation CreateProgram(
    $input: CreateProgramInput!
    $condition: ModelProgramConditionInput
  ) {
    createProgram(input: $input, condition: $condition) {
      id
      programType
      programName
      programDescription
      programStartMonth
      programEndMonth
      usesUpfrontPricing
      usesGoogleSheetPricing
      googleSheetSheetId
      googleSheetTabName
      googleSheetRangeMapping
      googleSheetResultsMapping
      upfrontPricingTable
      probingQuestionsJson
      active
      isUpsell
      excludedZips
      productOffering
      conditions
      alwaysOnTop
      alwaysAvailable
      crmProgamCode
      seasonalText
      alternativeApplicationString
      useProbingQuestions
      useRealGreenPricingEngine
      realGreenPrepayDiscountCode
      realGreenApplicationDiscountCode
      createdAt
      updatedAt
      company {
        id
        companyName
        customerName
        customerEmail
        customerPhone
        customerAddress
        customerCity
        customerState
        customerZip
        serviceTax
        maxQuotableSize
        minQuotableSize
        autoPayAvailable
        prePayAvailable
        autoPayAlternativeLabel
        autoPayPriceMethod
        autoPayMonthlyPricePeriod
        bypassPayment
        crmSolution
        crmPortalUrl
        lawnbotEmployee
        marketingSource
        callStatusCode
        callActionReason
        crmNewRecordStatusScode
        crmQuoteStatusCode
        crmSaleStatusCode
        crmCompanyId
        crmRoute
        crmDayCode
        crmHandheld
        dashboardUsername
        dashboardGreetingName
        dashboardEmailAddress
        paymentType
        termsOfService
        createdAt
        updatedAt
        branding {
          id
          instaQuoteMessage
          companyLogo
          companyAvatar
          tagLine
          valueAddImage
          valueAddDescription
          aboutUsAssetUrl
          aboutUsAssetType
          quoteModalImage
          createdAt
          updatedAt
          __typename
        }
        bots {
          nextToken
          __typename
        }
        programs {
          nextToken
          __typename
        }
        priceTables {
          nextToken
          __typename
        }
        __typename
      }
      services {
        items {
          id
          serviceName
          roundNumber
          priceTableId
          cutOffDate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateProgram = /* GraphQL */ `
  mutation UpdateProgram(
    $input: UpdateProgramInput!
    $condition: ModelProgramConditionInput
  ) {
    updateProgram(input: $input, condition: $condition) {
      id
      programType
      programName
      programDescription
      programStartMonth
      programEndMonth
      usesUpfrontPricing
      usesGoogleSheetPricing
      googleSheetSheetId
      googleSheetTabName
      googleSheetRangeMapping
      googleSheetResultsMapping
      upfrontPricingTable
      probingQuestionsJson
      active
      isUpsell
      excludedZips
      productOffering
      conditions
      alwaysOnTop
      alwaysAvailable
      crmProgamCode
      seasonalText
      alternativeApplicationString
      useProbingQuestions
      useRealGreenPricingEngine
      realGreenPrepayDiscountCode
      realGreenApplicationDiscountCode
      createdAt
      updatedAt
      company {
        id
        companyName
        customerName
        customerEmail
        customerPhone
        customerAddress
        customerCity
        customerState
        customerZip
        serviceTax
        maxQuotableSize
        minQuotableSize
        autoPayAvailable
        prePayAvailable
        autoPayAlternativeLabel
        autoPayPriceMethod
        autoPayMonthlyPricePeriod
        bypassPayment
        crmSolution
        crmPortalUrl
        lawnbotEmployee
        marketingSource
        callStatusCode
        callActionReason
        crmNewRecordStatusScode
        crmQuoteStatusCode
        crmSaleStatusCode
        crmCompanyId
        crmRoute
        crmDayCode
        crmHandheld
        dashboardUsername
        dashboardGreetingName
        dashboardEmailAddress
        paymentType
        termsOfService
        createdAt
        updatedAt
        branding {
          id
          instaQuoteMessage
          companyLogo
          companyAvatar
          tagLine
          valueAddImage
          valueAddDescription
          aboutUsAssetUrl
          aboutUsAssetType
          quoteModalImage
          createdAt
          updatedAt
          __typename
        }
        bots {
          nextToken
          __typename
        }
        programs {
          nextToken
          __typename
        }
        priceTables {
          nextToken
          __typename
        }
        __typename
      }
      services {
        items {
          id
          serviceName
          roundNumber
          priceTableId
          cutOffDate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteProgram = /* GraphQL */ `
  mutation DeleteProgram(
    $input: DeleteProgramInput!
    $condition: ModelProgramConditionInput
  ) {
    deleteProgram(input: $input, condition: $condition) {
      id
      programType
      programName
      programDescription
      programStartMonth
      programEndMonth
      usesUpfrontPricing
      usesGoogleSheetPricing
      googleSheetSheetId
      googleSheetTabName
      googleSheetRangeMapping
      googleSheetResultsMapping
      upfrontPricingTable
      probingQuestionsJson
      active
      isUpsell
      excludedZips
      productOffering
      conditions
      alwaysOnTop
      alwaysAvailable
      crmProgamCode
      seasonalText
      alternativeApplicationString
      useProbingQuestions
      useRealGreenPricingEngine
      realGreenPrepayDiscountCode
      realGreenApplicationDiscountCode
      createdAt
      updatedAt
      company {
        id
        companyName
        customerName
        customerEmail
        customerPhone
        customerAddress
        customerCity
        customerState
        customerZip
        serviceTax
        maxQuotableSize
        minQuotableSize
        autoPayAvailable
        prePayAvailable
        autoPayAlternativeLabel
        autoPayPriceMethod
        autoPayMonthlyPricePeriod
        bypassPayment
        crmSolution
        crmPortalUrl
        lawnbotEmployee
        marketingSource
        callStatusCode
        callActionReason
        crmNewRecordStatusScode
        crmQuoteStatusCode
        crmSaleStatusCode
        crmCompanyId
        crmRoute
        crmDayCode
        crmHandheld
        dashboardUsername
        dashboardGreetingName
        dashboardEmailAddress
        paymentType
        termsOfService
        createdAt
        updatedAt
        branding {
          id
          instaQuoteMessage
          companyLogo
          companyAvatar
          tagLine
          valueAddImage
          valueAddDescription
          aboutUsAssetUrl
          aboutUsAssetType
          quoteModalImage
          createdAt
          updatedAt
          __typename
        }
        bots {
          nextToken
          __typename
        }
        programs {
          nextToken
          __typename
        }
        priceTables {
          nextToken
          __typename
        }
        __typename
      }
      services {
        items {
          id
          serviceName
          roundNumber
          priceTableId
          cutOffDate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createService = /* GraphQL */ `
  mutation CreateService(
    $input: CreateServiceInput!
    $condition: ModelServiceConditionInput
  ) {
    createService(input: $input, condition: $condition) {
      id
      serviceName
      roundNumber
      priceTableId
      cutOffDate
      createdAt
      updatedAt
      program {
        id
        programType
        programName
        programDescription
        programStartMonth
        programEndMonth
        usesUpfrontPricing
        usesGoogleSheetPricing
        googleSheetSheetId
        googleSheetTabName
        googleSheetRangeMapping
        googleSheetResultsMapping
        upfrontPricingTable
        probingQuestionsJson
        active
        isUpsell
        excludedZips
        productOffering
        conditions
        alwaysOnTop
        alwaysAvailable
        crmProgamCode
        seasonalText
        alternativeApplicationString
        useProbingQuestions
        useRealGreenPricingEngine
        realGreenPrepayDiscountCode
        realGreenApplicationDiscountCode
        createdAt
        updatedAt
        company {
          id
          companyName
          customerName
          customerEmail
          customerPhone
          customerAddress
          customerCity
          customerState
          customerZip
          serviceTax
          maxQuotableSize
          minQuotableSize
          autoPayAvailable
          prePayAvailable
          autoPayAlternativeLabel
          autoPayPriceMethod
          autoPayMonthlyPricePeriod
          bypassPayment
          crmSolution
          crmPortalUrl
          lawnbotEmployee
          marketingSource
          callStatusCode
          callActionReason
          crmNewRecordStatusScode
          crmQuoteStatusCode
          crmSaleStatusCode
          crmCompanyId
          crmRoute
          crmDayCode
          crmHandheld
          dashboardUsername
          dashboardGreetingName
          dashboardEmailAddress
          paymentType
          termsOfService
          createdAt
          updatedAt
          __typename
        }
        services {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const updateService = /* GraphQL */ `
  mutation UpdateService(
    $input: UpdateServiceInput!
    $condition: ModelServiceConditionInput
  ) {
    updateService(input: $input, condition: $condition) {
      id
      serviceName
      roundNumber
      priceTableId
      cutOffDate
      createdAt
      updatedAt
      program {
        id
        programType
        programName
        programDescription
        programStartMonth
        programEndMonth
        usesUpfrontPricing
        usesGoogleSheetPricing
        googleSheetSheetId
        googleSheetTabName
        googleSheetRangeMapping
        googleSheetResultsMapping
        upfrontPricingTable
        probingQuestionsJson
        active
        isUpsell
        excludedZips
        productOffering
        conditions
        alwaysOnTop
        alwaysAvailable
        crmProgamCode
        seasonalText
        alternativeApplicationString
        useProbingQuestions
        useRealGreenPricingEngine
        realGreenPrepayDiscountCode
        realGreenApplicationDiscountCode
        createdAt
        updatedAt
        company {
          id
          companyName
          customerName
          customerEmail
          customerPhone
          customerAddress
          customerCity
          customerState
          customerZip
          serviceTax
          maxQuotableSize
          minQuotableSize
          autoPayAvailable
          prePayAvailable
          autoPayAlternativeLabel
          autoPayPriceMethod
          autoPayMonthlyPricePeriod
          bypassPayment
          crmSolution
          crmPortalUrl
          lawnbotEmployee
          marketingSource
          callStatusCode
          callActionReason
          crmNewRecordStatusScode
          crmQuoteStatusCode
          crmSaleStatusCode
          crmCompanyId
          crmRoute
          crmDayCode
          crmHandheld
          dashboardUsername
          dashboardGreetingName
          dashboardEmailAddress
          paymentType
          termsOfService
          createdAt
          updatedAt
          __typename
        }
        services {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const deleteService = /* GraphQL */ `
  mutation DeleteService(
    $input: DeleteServiceInput!
    $condition: ModelServiceConditionInput
  ) {
    deleteService(input: $input, condition: $condition) {
      id
      serviceName
      roundNumber
      priceTableId
      cutOffDate
      createdAt
      updatedAt
      program {
        id
        programType
        programName
        programDescription
        programStartMonth
        programEndMonth
        usesUpfrontPricing
        usesGoogleSheetPricing
        googleSheetSheetId
        googleSheetTabName
        googleSheetRangeMapping
        googleSheetResultsMapping
        upfrontPricingTable
        probingQuestionsJson
        active
        isUpsell
        excludedZips
        productOffering
        conditions
        alwaysOnTop
        alwaysAvailable
        crmProgamCode
        seasonalText
        alternativeApplicationString
        useProbingQuestions
        useRealGreenPricingEngine
        realGreenPrepayDiscountCode
        realGreenApplicationDiscountCode
        createdAt
        updatedAt
        company {
          id
          companyName
          customerName
          customerEmail
          customerPhone
          customerAddress
          customerCity
          customerState
          customerZip
          serviceTax
          maxQuotableSize
          minQuotableSize
          autoPayAvailable
          prePayAvailable
          autoPayAlternativeLabel
          autoPayPriceMethod
          autoPayMonthlyPricePeriod
          bypassPayment
          crmSolution
          crmPortalUrl
          lawnbotEmployee
          marketingSource
          callStatusCode
          callActionReason
          crmNewRecordStatusScode
          crmQuoteStatusCode
          crmSaleStatusCode
          crmCompanyId
          crmRoute
          crmDayCode
          crmHandheld
          dashboardUsername
          dashboardGreetingName
          dashboardEmailAddress
          paymentType
          termsOfService
          createdAt
          updatedAt
          __typename
        }
        services {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const createPriceTable = /* GraphQL */ `
  mutation CreatePriceTable(
    $input: CreatePriceTableInput!
    $condition: ModelPriceTableConditionInput
  ) {
    createPriceTable(input: $input, condition: $condition) {
      id
      year
      name
      prePayDiscount
      prePayCutoff
      serviceType
      conditions
      createdAt
      updatedAt
      company {
        id
        companyName
        customerName
        customerEmail
        customerPhone
        customerAddress
        customerCity
        customerState
        customerZip
        serviceTax
        maxQuotableSize
        minQuotableSize
        autoPayAvailable
        prePayAvailable
        autoPayAlternativeLabel
        autoPayPriceMethod
        autoPayMonthlyPricePeriod
        bypassPayment
        crmSolution
        crmPortalUrl
        lawnbotEmployee
        marketingSource
        callStatusCode
        callActionReason
        crmNewRecordStatusScode
        crmQuoteStatusCode
        crmSaleStatusCode
        crmCompanyId
        crmRoute
        crmDayCode
        crmHandheld
        dashboardUsername
        dashboardGreetingName
        dashboardEmailAddress
        paymentType
        termsOfService
        createdAt
        updatedAt
        branding {
          id
          instaQuoteMessage
          companyLogo
          companyAvatar
          tagLine
          valueAddImage
          valueAddDescription
          aboutUsAssetUrl
          aboutUsAssetType
          quoteModalImage
          createdAt
          updatedAt
          __typename
        }
        bots {
          nextToken
          __typename
        }
        programs {
          nextToken
          __typename
        }
        priceTables {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const updatePriceTable = /* GraphQL */ `
  mutation UpdatePriceTable(
    $input: UpdatePriceTableInput!
    $condition: ModelPriceTableConditionInput
  ) {
    updatePriceTable(input: $input, condition: $condition) {
      id
      year
      name
      prePayDiscount
      prePayCutoff
      serviceType
      conditions
      createdAt
      updatedAt
      company {
        id
        companyName
        customerName
        customerEmail
        customerPhone
        customerAddress
        customerCity
        customerState
        customerZip
        serviceTax
        maxQuotableSize
        minQuotableSize
        autoPayAvailable
        prePayAvailable
        autoPayAlternativeLabel
        autoPayPriceMethod
        autoPayMonthlyPricePeriod
        bypassPayment
        crmSolution
        crmPortalUrl
        lawnbotEmployee
        marketingSource
        callStatusCode
        callActionReason
        crmNewRecordStatusScode
        crmQuoteStatusCode
        crmSaleStatusCode
        crmCompanyId
        crmRoute
        crmDayCode
        crmHandheld
        dashboardUsername
        dashboardGreetingName
        dashboardEmailAddress
        paymentType
        termsOfService
        createdAt
        updatedAt
        branding {
          id
          instaQuoteMessage
          companyLogo
          companyAvatar
          tagLine
          valueAddImage
          valueAddDescription
          aboutUsAssetUrl
          aboutUsAssetType
          quoteModalImage
          createdAt
          updatedAt
          __typename
        }
        bots {
          nextToken
          __typename
        }
        programs {
          nextToken
          __typename
        }
        priceTables {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const deletePriceTable = /* GraphQL */ `
  mutation DeletePriceTable(
    $input: DeletePriceTableInput!
    $condition: ModelPriceTableConditionInput
  ) {
    deletePriceTable(input: $input, condition: $condition) {
      id
      year
      name
      prePayDiscount
      prePayCutoff
      serviceType
      conditions
      createdAt
      updatedAt
      company {
        id
        companyName
        customerName
        customerEmail
        customerPhone
        customerAddress
        customerCity
        customerState
        customerZip
        serviceTax
        maxQuotableSize
        minQuotableSize
        autoPayAvailable
        prePayAvailable
        autoPayAlternativeLabel
        autoPayPriceMethod
        autoPayMonthlyPricePeriod
        bypassPayment
        crmSolution
        crmPortalUrl
        lawnbotEmployee
        marketingSource
        callStatusCode
        callActionReason
        crmNewRecordStatusScode
        crmQuoteStatusCode
        crmSaleStatusCode
        crmCompanyId
        crmRoute
        crmDayCode
        crmHandheld
        dashboardUsername
        dashboardGreetingName
        dashboardEmailAddress
        paymentType
        termsOfService
        createdAt
        updatedAt
        branding {
          id
          instaQuoteMessage
          companyLogo
          companyAvatar
          tagLine
          valueAddImage
          valueAddDescription
          aboutUsAssetUrl
          aboutUsAssetType
          quoteModalImage
          createdAt
          updatedAt
          __typename
        }
        bots {
          nextToken
          __typename
        }
        programs {
          nextToken
          __typename
        }
        priceTables {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const createPriceTableRows = /* GraphQL */ `
  mutation CreatePriceTableRows(
    $input: CreatePriceTableRowsInput!
    $condition: ModelPriceTableRowsConditionInput
  ) {
    createPriceTableRows(input: $input, condition: $condition) {
      id
      priceTableRowsPriceTableId
      start
      end
      price
      units
      createdAt
      updatedAt
      priceTable {
        version
        priceTableCompanyId
        id
        isDeleted
        year
        name
        prePayDiscount
        prePayCutoff
        serviceType
        conditions
        createdAt
        updatedAt
        company {
          id
          companyName
          customerName
          customerEmail
          customerPhone
          customerAddress
          customerCity
          customerState
          customerZip
          serviceTax
          maxQuotableSize
          minQuotableSize
          autoPayAvailable
          prePayAvailable
          autoPayAlternativeLabel
          autoPayPriceMethod
          autoPayMonthlyPricePeriod
          bypassPayment
          crmSolution
          crmPortalUrl
          lawnbotEmployee
          marketingSource
          callStatusCode
          callActionReason
          crmNewRecordStatusScode
          crmQuoteStatusCode
          crmSaleStatusCode
          crmCompanyId
          crmRoute
          crmDayCode
          crmHandheld
          dashboardUsername
          dashboardGreetingName
          dashboardEmailAddress
          paymentType
          termsOfService
          createdAt
          updatedAt
          __typename
        }
        rows {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const updatePriceTableRows = /* GraphQL */ `
  mutation UpdatePriceTableRows(
    $input: UpdatePriceTableRowsInput!
    $condition: ModelPriceTableRowsConditionInput
  ) {
    updatePriceTableRows(input: $input, condition: $condition) {
      id
      priceTableRowsPriceTableId
      start
      end
      price
      units
      createdAt
      updatedAt
      priceTable {
        version
        priceTableCompanyId
        id
        isDeleted
        year
        name
        prePayDiscount
        prePayCutoff
        serviceType
        conditions
        createdAt
        updatedAt
        company {
          id
          companyName
          customerName
          customerEmail
          customerPhone
          customerAddress
          customerCity
          customerState
          customerZip
          serviceTax
          maxQuotableSize
          minQuotableSize
          autoPayAvailable
          prePayAvailable
          autoPayAlternativeLabel
          autoPayPriceMethod
          autoPayMonthlyPricePeriod
          bypassPayment
          crmSolution
          crmPortalUrl
          lawnbotEmployee
          marketingSource
          callStatusCode
          callActionReason
          crmNewRecordStatusScode
          crmQuoteStatusCode
          crmSaleStatusCode
          crmCompanyId
          crmRoute
          crmDayCode
          crmHandheld
          dashboardUsername
          dashboardGreetingName
          dashboardEmailAddress
          paymentType
          termsOfService
          createdAt
          updatedAt
          __typename
        }
        rows {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const deletePriceTableRows = /* GraphQL */ `
  mutation DeletePriceTableRows(
    $input: DeletePriceTableRowsInput!
    $condition: ModelPriceTableRowsConditionInput
  ) {
    deletePriceTableRows(input: $input, condition: $condition) {
      id
      priceTableRowsPriceTableId
      start
      end
      price
      units
      createdAt
      updatedAt
      priceTable {
        version
        priceTableCompanyId
        id
        isDeleted
        year
        name
        prePayDiscount
        prePayCutoff
        serviceType
        conditions
        createdAt
        updatedAt
        company {
          id
          companyName
          customerName
          customerEmail
          customerPhone
          customerAddress
          customerCity
          customerState
          customerZip
          serviceTax
          maxQuotableSize
          minQuotableSize
          autoPayAvailable
          prePayAvailable
          autoPayAlternativeLabel
          autoPayPriceMethod
          autoPayMonthlyPricePeriod
          bypassPayment
          crmSolution
          crmPortalUrl
          lawnbotEmployee
          marketingSource
          callStatusCode
          callActionReason
          crmNewRecordStatusScode
          crmQuoteStatusCode
          crmSaleStatusCode
          crmCompanyId
          crmRoute
          crmDayCode
          crmHandheld
          dashboardUsername
          dashboardGreetingName
          dashboardEmailAddress
          paymentType
          termsOfService
          createdAt
          updatedAt
          __typename
        }
        rows {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const createServiceType = /* GraphQL */ `
  mutation CreateServiceType(
    $input: CreateServiceTypeInput!
    $condition: ModelServiceTypeConditionInput
  ) {
    createServiceType(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateServiceType = /* GraphQL */ `
  mutation UpdateServiceType(
    $input: UpdateServiceTypeInput!
    $condition: ModelServiceTypeConditionInput
  ) {
    updateServiceType(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteServiceType = /* GraphQL */ `
  mutation DeleteServiceType(
    $input: DeleteServiceTypeInput!
    $condition: ModelServiceTypeConditionInput
  ) {
    deleteServiceType(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCompanyDataServices = /* GraphQL */ `
  mutation CreateCompanyDataServices(
    $input: CreateCompanyDataServicesInput!
    $condition: ModelCompanyDataServicesConditionInput
  ) {
    createCompanyDataServices(input: $input, condition: $condition) {
      id
      stripeTestSecret
      stripeSecret
      realGreenAPIKey
      serviceAssistantId
      zillowAPIKey
      airTableBase
      companyDataServicesCompanyId
      airTableTableName
      googleSheetsClientEmail
      googleSheetsPrivateKey
      servmanApiKey
      servmanApiAuth
      servmanApiUrl
      createdAt
      updatedAt
      company {
        version
        id
        approved
        approvedBy
        approvedOn
        companyName
        customerName
        customerEmail
        customerPhone
        customerAddress
        customerCity
        customerState
        customerZip
        companyBrandingId
        companyDataServicesId
        currency
        serviceTax
        maxQuotableSize
        minQuotableSize
        autoPayAvailable
        prePayAvailable
        autoPayAlternativeLabel
        autoPayPriceMethod
        taxOverride
        autoPayMonthlyPricePeriod
        bypassPayment
        crmSolution
        crmPortalUrl
        lawnbotEmployee
        marketingSource
        callStatusCode
        callActionReason
        crmNewRecordStatusScode
        crmQuoteStatusCode
        crmSaleStatusCode
        pestPacTenantId
        crmCompanyId
        crmRoute
        crmDayCode
        crmHandheld
        dashboardUsername
        dashboardGreetingName
        dashboardEmailAddress
        paymentType
        termsOfService
        onboardingJson
        serviceAgreementContent
        noServiceAgreementContent
        serviceAgreementTellMeMore
        emergencyTellMeMore
        gettingStartedText
        createdAt
        updatedAt
        dataServices {
          id
          stripeTestSecret
          stripeSecret
          realGreenAPIKey
          serviceAssistantId
          zillowAPIKey
          airTableBase
          companyDataServicesCompanyId
          airTableTableName
          googleSheetsClientEmail
          googleSheetsPrivateKey
          servmanApiKey
          servmanApiAuth
          servmanApiUrl
          createdAt
          updatedAt
          __typename
        }
        offers {
          nextToken
          __typename
        }
        branding {
          version
          id
          companyBrandingCompanyId
          instaQuoteMessage
          companyLogo
          companyAvatar
          tagLine
          valueAddImage
          valueAddDescription
          aboutUsAssetUrl
          aboutUsAssetType
          quoteModalImage
          createdAt
          updatedAt
          __typename
        }
        bots {
          nextToken
          __typename
        }
        programs {
          nextToken
          __typename
        }
        priceTables {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const updateCompanyDataServices = /* GraphQL */ `
  mutation UpdateCompanyDataServices(
    $input: UpdateCompanyDataServicesInput!
    $condition: ModelCompanyDataServicesConditionInput
  ) {
    updateCompanyDataServices(input: $input, condition: $condition) {
      id
      stripeTestSecret
      stripeSecret
      realGreenAPIKey
      serviceAssistantId
      zillowAPIKey
      airTableBase
      companyDataServicesCompanyId
      airTableTableName
      googleSheetsClientEmail
      googleSheetsPrivateKey
      servmanApiKey
      servmanApiAuth
      servmanApiUrl
      createdAt
      updatedAt
      company {
        version
        id
        approved
        approvedBy
        approvedOn
        companyName
        customerName
        customerEmail
        customerPhone
        customerAddress
        customerCity
        customerState
        customerZip
        companyBrandingId
        companyDataServicesId
        currency
        serviceTax
        maxQuotableSize
        minQuotableSize
        autoPayAvailable
        prePayAvailable
        autoPayAlternativeLabel
        autoPayPriceMethod
        taxOverride
        autoPayMonthlyPricePeriod
        bypassPayment
        crmSolution
        crmPortalUrl
        lawnbotEmployee
        marketingSource
        callStatusCode
        callActionReason
        crmNewRecordStatusScode
        crmQuoteStatusCode
        crmSaleStatusCode
        pestPacTenantId
        crmCompanyId
        crmRoute
        crmDayCode
        crmHandheld
        dashboardUsername
        dashboardGreetingName
        dashboardEmailAddress
        paymentType
        termsOfService
        onboardingJson
        serviceAgreementContent
        noServiceAgreementContent
        serviceAgreementTellMeMore
        emergencyTellMeMore
        gettingStartedText
        createdAt
        updatedAt
        dataServices {
          id
          stripeTestSecret
          stripeSecret
          realGreenAPIKey
          serviceAssistantId
          zillowAPIKey
          airTableBase
          companyDataServicesCompanyId
          airTableTableName
          googleSheetsClientEmail
          googleSheetsPrivateKey
          servmanApiKey
          servmanApiAuth
          servmanApiUrl
          createdAt
          updatedAt
          __typename
        }
        offers {
          nextToken
          __typename
        }
        branding {
          version
          id
          companyBrandingCompanyId
          instaQuoteMessage
          companyLogo
          companyAvatar
          tagLine
          valueAddImage
          valueAddDescription
          aboutUsAssetUrl
          aboutUsAssetType
          quoteModalImage
          createdAt
          updatedAt
          __typename
        }
        bots {
          nextToken
          __typename
        }
        programs {
          nextToken
          __typename
        }
        priceTables {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const deleteCompanyDataServices = /* GraphQL */ `
  mutation DeleteCompanyDataServices(
    $input: DeleteCompanyDataServicesInput!
    $condition: ModelCompanyDataServicesConditionInput
  ) {
    deleteCompanyDataServices(input: $input, condition: $condition) {
      id
      stripeTestSecret
      stripeSecret
      realGreenAPIKey
      serviceAssistantId
      zillowAPIKey
      airTableBase
      companyDataServicesCompanyId
      airTableTableName
      googleSheetsClientEmail
      googleSheetsPrivateKey
      servmanApiKey
      servmanApiAuth
      servmanApiUrl
      createdAt
      updatedAt
      company {
        version
        id
        approved
        approvedBy
        approvedOn
        companyName
        customerName
        customerEmail
        customerPhone
        customerAddress
        customerCity
        customerState
        customerZip
        companyBrandingId
        companyDataServicesId
        currency
        serviceTax
        maxQuotableSize
        minQuotableSize
        autoPayAvailable
        prePayAvailable
        autoPayAlternativeLabel
        autoPayPriceMethod
        taxOverride
        autoPayMonthlyPricePeriod
        bypassPayment
        crmSolution
        crmPortalUrl
        lawnbotEmployee
        marketingSource
        callStatusCode
        callActionReason
        crmNewRecordStatusScode
        crmQuoteStatusCode
        crmSaleStatusCode
        pestPacTenantId
        crmCompanyId
        crmRoute
        crmDayCode
        crmHandheld
        dashboardUsername
        dashboardGreetingName
        dashboardEmailAddress
        paymentType
        termsOfService
        onboardingJson
        serviceAgreementContent
        noServiceAgreementContent
        serviceAgreementTellMeMore
        emergencyTellMeMore
        gettingStartedText
        createdAt
        updatedAt
        dataServices {
          id
          stripeTestSecret
          stripeSecret
          realGreenAPIKey
          serviceAssistantId
          zillowAPIKey
          airTableBase
          companyDataServicesCompanyId
          airTableTableName
          googleSheetsClientEmail
          googleSheetsPrivateKey
          servmanApiKey
          servmanApiAuth
          servmanApiUrl
          createdAt
          updatedAt
          __typename
        }
        offers {
          nextToken
          __typename
        }
        branding {
          version
          id
          companyBrandingCompanyId
          instaQuoteMessage
          companyLogo
          companyAvatar
          tagLine
          valueAddImage
          valueAddDescription
          aboutUsAssetUrl
          aboutUsAssetType
          quoteModalImage
          createdAt
          updatedAt
          __typename
        }
        bots {
          nextToken
          __typename
        }
        programs {
          nextToken
          __typename
        }
        priceTables {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const createOffer = /* GraphQL */ `
  mutation CreateOffer(
    $input: CreateOfferInput!
    $condition: ModelOfferConditionInput
  ) {
    createOffer(input: $input, condition: $condition) {
      id
      name
      startDate
      endDate
      programs
      offerCompanyId
      active
      hide
      createdAt
      updatedAt
      company {
        version
        id
        approved
        approvedBy
        approvedOn
        companyName
        customerName
        customerEmail
        customerPhone
        customerAddress
        customerCity
        customerState
        customerZip
        companyBrandingId
        companyDataServicesId
        currency
        serviceTax
        maxQuotableSize
        minQuotableSize
        autoPayAvailable
        prePayAvailable
        autoPayAlternativeLabel
        autoPayPriceMethod
        taxOverride
        autoPayMonthlyPricePeriod
        bypassPayment
        crmSolution
        crmPortalUrl
        lawnbotEmployee
        marketingSource
        callStatusCode
        callActionReason
        crmNewRecordStatusScode
        crmQuoteStatusCode
        crmSaleStatusCode
        pestPacTenantId
        crmCompanyId
        crmRoute
        crmDayCode
        crmHandheld
        dashboardUsername
        dashboardGreetingName
        dashboardEmailAddress
        paymentType
        termsOfService
        onboardingJson
        serviceAgreementContent
        noServiceAgreementContent
        serviceAgreementTellMeMore
        emergencyTellMeMore
        gettingStartedText
        createdAt
        updatedAt
        dataServices {
          id
          stripeTestSecret
          stripeSecret
          realGreenAPIKey
          serviceAssistantId
          zillowAPIKey
          airTableBase
          companyDataServicesCompanyId
          airTableTableName
          googleSheetsClientEmail
          googleSheetsPrivateKey
          servmanApiKey
          servmanApiAuth
          servmanApiUrl
          createdAt
          updatedAt
          __typename
        }
        offers {
          nextToken
          __typename
        }
        branding {
          version
          id
          companyBrandingCompanyId
          instaQuoteMessage
          companyLogo
          companyAvatar
          tagLine
          valueAddImage
          valueAddDescription
          aboutUsAssetUrl
          aboutUsAssetType
          quoteModalImage
          createdAt
          updatedAt
          __typename
        }
        bots {
          nextToken
          __typename
        }
        programs {
          nextToken
          __typename
        }
        priceTables {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const updateOffer = /* GraphQL */ `
  mutation UpdateOffer(
    $input: UpdateOfferInput!
    $condition: ModelOfferConditionInput
  ) {
    updateOffer(input: $input, condition: $condition) {
      id
      name
      startDate
      endDate
      programs
      offerCompanyId
      active
      hide
      createdAt
      updatedAt
      company {
        version
        id
        approved
        approvedBy
        approvedOn
        companyName
        customerName
        customerEmail
        customerPhone
        customerAddress
        customerCity
        customerState
        customerZip
        companyBrandingId
        companyDataServicesId
        currency
        serviceTax
        maxQuotableSize
        minQuotableSize
        autoPayAvailable
        prePayAvailable
        autoPayAlternativeLabel
        autoPayPriceMethod
        taxOverride
        autoPayMonthlyPricePeriod
        bypassPayment
        crmSolution
        crmPortalUrl
        lawnbotEmployee
        marketingSource
        callStatusCode
        callActionReason
        crmNewRecordStatusScode
        crmQuoteStatusCode
        crmSaleStatusCode
        pestPacTenantId
        crmCompanyId
        crmRoute
        crmDayCode
        crmHandheld
        dashboardUsername
        dashboardGreetingName
        dashboardEmailAddress
        paymentType
        termsOfService
        onboardingJson
        serviceAgreementContent
        noServiceAgreementContent
        serviceAgreementTellMeMore
        emergencyTellMeMore
        gettingStartedText
        createdAt
        updatedAt
        dataServices {
          id
          stripeTestSecret
          stripeSecret
          realGreenAPIKey
          serviceAssistantId
          zillowAPIKey
          airTableBase
          companyDataServicesCompanyId
          airTableTableName
          googleSheetsClientEmail
          googleSheetsPrivateKey
          servmanApiKey
          servmanApiAuth
          servmanApiUrl
          createdAt
          updatedAt
          __typename
        }
        offers {
          nextToken
          __typename
        }
        branding {
          version
          id
          companyBrandingCompanyId
          instaQuoteMessage
          companyLogo
          companyAvatar
          tagLine
          valueAddImage
          valueAddDescription
          aboutUsAssetUrl
          aboutUsAssetType
          quoteModalImage
          createdAt
          updatedAt
          __typename
        }
        bots {
          nextToken
          __typename
        }
        programs {
          nextToken
          __typename
        }
        priceTables {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const deleteOffer = /* GraphQL */ `
  mutation DeleteOffer(
    $input: DeleteOfferInput!
    $condition: ModelOfferConditionInput
  ) {
    deleteOffer(input: $input, condition: $condition) {
      id
      name
      startDate
      endDate
      programs
      offerCompanyId
      active
      hide
      createdAt
      updatedAt
      company {
        version
        id
        approved
        approvedBy
        approvedOn
        companyName
        customerName
        customerEmail
        customerPhone
        customerAddress
        customerCity
        customerState
        customerZip
        companyBrandingId
        companyDataServicesId
        currency
        serviceTax
        maxQuotableSize
        minQuotableSize
        autoPayAvailable
        prePayAvailable
        autoPayAlternativeLabel
        autoPayPriceMethod
        taxOverride
        autoPayMonthlyPricePeriod
        bypassPayment
        crmSolution
        crmPortalUrl
        lawnbotEmployee
        marketingSource
        callStatusCode
        callActionReason
        crmNewRecordStatusScode
        crmQuoteStatusCode
        crmSaleStatusCode
        pestPacTenantId
        crmCompanyId
        crmRoute
        crmDayCode
        crmHandheld
        dashboardUsername
        dashboardGreetingName
        dashboardEmailAddress
        paymentType
        termsOfService
        onboardingJson
        serviceAgreementContent
        noServiceAgreementContent
        serviceAgreementTellMeMore
        emergencyTellMeMore
        gettingStartedText
        createdAt
        updatedAt
        dataServices {
          id
          stripeTestSecret
          stripeSecret
          realGreenAPIKey
          serviceAssistantId
          zillowAPIKey
          airTableBase
          companyDataServicesCompanyId
          airTableTableName
          googleSheetsClientEmail
          googleSheetsPrivateKey
          servmanApiKey
          servmanApiAuth
          servmanApiUrl
          createdAt
          updatedAt
          __typename
        }
        offers {
          nextToken
          __typename
        }
        branding {
          version
          id
          companyBrandingCompanyId
          instaQuoteMessage
          companyLogo
          companyAvatar
          tagLine
          valueAddImage
          valueAddDescription
          aboutUsAssetUrl
          aboutUsAssetType
          quoteModalImage
          createdAt
          updatedAt
          __typename
        }
        bots {
          nextToken
          __typename
        }
        programs {
          nextToken
          __typename
        }
        priceTables {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const createCompanyVersioned = /* GraphQL */ `
  mutation CreateCompanyVersioned(
    $input: CreateCompanyVersionedInput!
    $condition: ModelCompanyVersionedConditionInput
  ) {
    createCompanyVersioned(input: $input, condition: $condition) {
      version
      id
      approved
      approvedBy
      approvedOn
      companyName
      customerName
      customerEmail
      customerPhone
      customerAddress
      customerCity
      customerState
      customerZip
      companyBrandingId
      companyDataServicesId
      currency
      serviceTax
      maxQuotableSize
      minQuotableSize
      autoPayAvailable
      prePayAvailable
      autoPayAlternativeLabel
      autoPayPriceMethod
      taxOverride
      autoPayMonthlyPricePeriod
      bypassPayment
      crmSolution
      crmPortalUrl
      lawnbotEmployee
      marketingSource
      callStatusCode
      callActionReason
      crmNewRecordStatusScode
      crmQuoteStatusCode
      crmSaleStatusCode
      pestPacTenantId
      crmCompanyId
      crmRoute
      crmDayCode
      crmHandheld
      dashboardUsername
      dashboardGreetingName
      dashboardEmailAddress
      paymentType
      termsOfService
      onboardingJson
      serviceAgreementContent
      noServiceAgreementContent
      serviceAgreementTellMeMore
      emergencyTellMeMore
      gettingStartedText
      createdAt
      updatedAt
      dataServices {
        id
        stripeTestSecret
        stripeSecret
        realGreenAPIKey
        serviceAssistantId
        zillowAPIKey
        airTableBase
        companyDataServicesCompanyId
        airTableTableName
        googleSheetsClientEmail
        googleSheetsPrivateKey
        servmanApiKey
        servmanApiAuth
        servmanApiUrl
        createdAt
        updatedAt
        company {
          version
          id
          approved
          approvedBy
          approvedOn
          companyName
          customerName
          customerEmail
          customerPhone
          customerAddress
          customerCity
          customerState
          customerZip
          companyBrandingId
          companyDataServicesId
          currency
          serviceTax
          maxQuotableSize
          minQuotableSize
          autoPayAvailable
          prePayAvailable
          autoPayAlternativeLabel
          autoPayPriceMethod
          taxOverride
          autoPayMonthlyPricePeriod
          bypassPayment
          crmSolution
          crmPortalUrl
          lawnbotEmployee
          marketingSource
          callStatusCode
          callActionReason
          crmNewRecordStatusScode
          crmQuoteStatusCode
          crmSaleStatusCode
          pestPacTenantId
          crmCompanyId
          crmRoute
          crmDayCode
          crmHandheld
          dashboardUsername
          dashboardGreetingName
          dashboardEmailAddress
          paymentType
          termsOfService
          onboardingJson
          serviceAgreementContent
          noServiceAgreementContent
          serviceAgreementTellMeMore
          emergencyTellMeMore
          gettingStartedText
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      offers {
        items {
          id
          name
          startDate
          endDate
          programs
          offerCompanyId
          active
          hide
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      branding {
        version
        id
        companyBrandingCompanyId
        instaQuoteMessage
        companyLogo
        companyAvatar
        tagLine
        valueAddImage
        valueAddDescription
        aboutUsAssetUrl
        aboutUsAssetType
        quoteModalImage
        createdAt
        updatedAt
        company {
          version
          id
          approved
          approvedBy
          approvedOn
          companyName
          customerName
          customerEmail
          customerPhone
          customerAddress
          customerCity
          customerState
          customerZip
          companyBrandingId
          companyDataServicesId
          currency
          serviceTax
          maxQuotableSize
          minQuotableSize
          autoPayAvailable
          prePayAvailable
          autoPayAlternativeLabel
          autoPayPriceMethod
          taxOverride
          autoPayMonthlyPricePeriod
          bypassPayment
          crmSolution
          crmPortalUrl
          lawnbotEmployee
          marketingSource
          callStatusCode
          callActionReason
          crmNewRecordStatusScode
          crmQuoteStatusCode
          crmSaleStatusCode
          pestPacTenantId
          crmCompanyId
          crmRoute
          crmDayCode
          crmHandheld
          dashboardUsername
          dashboardGreetingName
          dashboardEmailAddress
          paymentType
          termsOfService
          onboardingJson
          serviceAgreementContent
          noServiceAgreementContent
          serviceAgreementTellMeMore
          emergencyTellMeMore
          gettingStartedText
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      bots {
        items {
          version
          id
          botCompanyId
          botTitle
          botName
          botType
          botVersion
          botStatus
          isLive
          botQuoteAnalyzingGif
          sqFtPercentage
          sqftEstimateSource
          zillowFallback
          learnMore
          objections
          preModal
          serviceQuestions
          lookupZipCodeTax
          zipCodes
          dontTaxZipCodes
          botSpeed
          willRedirect
          redirectUrl
          skipProbingQuestions
          greeting
          salutation
          hideSummary
          isMetric
          measurementMode
          hideArielAndStreetView
          customSchedulingVerbiage
          forceKey
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      programs {
        items {
          version
          id
          programCompanyId
          isDeleted
          programType
          programName
          programDescription
          programStartMonth
          programEndMonth
          hideDiscountText
          programProbingQuestionsId
          probingQuestionsJson
          active
          isUpsell
          excludedZips
          productOffering
          conditions
          alwaysOnTop
          alwaysAvailable
          crmProgamCode
          seasonalText
          usesUpfrontPricing
          usesGoogleSheetPricing
          googleSheetSheetId
          googleSheetTabName
          googleSheetRangeMapping
          googleSheetResultsMapping
          upfrontPricingTable
          recurringPricingTable
          alternativeApplicationString
          useProbingQuestions
          useRealGreenPricingEngine
          realGreenPrepayDiscountCode
          realGreenApplicationDiscountCode
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      priceTables {
        items {
          version
          priceTableCompanyId
          id
          isDeleted
          year
          name
          prePayDiscount
          prePayCutoff
          serviceType
          conditions
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateCompanyVersioned = /* GraphQL */ `
  mutation UpdateCompanyVersioned(
    $input: UpdateCompanyVersionedInput!
    $condition: ModelCompanyVersionedConditionInput
  ) {
    updateCompanyVersioned(input: $input, condition: $condition) {
      version
      id
      approved
      approvedBy
      approvedOn
      companyName
      customerName
      customerEmail
      customerPhone
      customerAddress
      customerCity
      customerState
      customerZip
      companyBrandingId
      companyDataServicesId
      currency
      serviceTax
      maxQuotableSize
      minQuotableSize
      autoPayAvailable
      prePayAvailable
      autoPayAlternativeLabel
      autoPayPriceMethod
      taxOverride
      autoPayMonthlyPricePeriod
      bypassPayment
      crmSolution
      crmPortalUrl
      lawnbotEmployee
      marketingSource
      callStatusCode
      callActionReason
      crmNewRecordStatusScode
      crmQuoteStatusCode
      crmSaleStatusCode
      pestPacTenantId
      crmCompanyId
      crmRoute
      crmDayCode
      crmHandheld
      dashboardUsername
      dashboardGreetingName
      dashboardEmailAddress
      paymentType
      termsOfService
      onboardingJson
      serviceAgreementContent
      noServiceAgreementContent
      serviceAgreementTellMeMore
      emergencyTellMeMore
      gettingStartedText
      createdAt
      updatedAt
      dataServices {
        id
        stripeTestSecret
        stripeSecret
        realGreenAPIKey
        serviceAssistantId
        zillowAPIKey
        airTableBase
        companyDataServicesCompanyId
        airTableTableName
        googleSheetsClientEmail
        googleSheetsPrivateKey
        servmanApiKey
        servmanApiAuth
        servmanApiUrl
        createdAt
        updatedAt
        company {
          version
          id
          approved
          approvedBy
          approvedOn
          companyName
          customerName
          customerEmail
          customerPhone
          customerAddress
          customerCity
          customerState
          customerZip
          companyBrandingId
          companyDataServicesId
          currency
          serviceTax
          maxQuotableSize
          minQuotableSize
          autoPayAvailable
          prePayAvailable
          autoPayAlternativeLabel
          autoPayPriceMethod
          taxOverride
          autoPayMonthlyPricePeriod
          bypassPayment
          crmSolution
          crmPortalUrl
          lawnbotEmployee
          marketingSource
          callStatusCode
          callActionReason
          crmNewRecordStatusScode
          crmQuoteStatusCode
          crmSaleStatusCode
          pestPacTenantId
          crmCompanyId
          crmRoute
          crmDayCode
          crmHandheld
          dashboardUsername
          dashboardGreetingName
          dashboardEmailAddress
          paymentType
          termsOfService
          onboardingJson
          serviceAgreementContent
          noServiceAgreementContent
          serviceAgreementTellMeMore
          emergencyTellMeMore
          gettingStartedText
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      offers {
        items {
          id
          name
          startDate
          endDate
          programs
          offerCompanyId
          active
          hide
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      branding {
        version
        id
        companyBrandingCompanyId
        instaQuoteMessage
        companyLogo
        companyAvatar
        tagLine
        valueAddImage
        valueAddDescription
        aboutUsAssetUrl
        aboutUsAssetType
        quoteModalImage
        createdAt
        updatedAt
        company {
          version
          id
          approved
          approvedBy
          approvedOn
          companyName
          customerName
          customerEmail
          customerPhone
          customerAddress
          customerCity
          customerState
          customerZip
          companyBrandingId
          companyDataServicesId
          currency
          serviceTax
          maxQuotableSize
          minQuotableSize
          autoPayAvailable
          prePayAvailable
          autoPayAlternativeLabel
          autoPayPriceMethod
          taxOverride
          autoPayMonthlyPricePeriod
          bypassPayment
          crmSolution
          crmPortalUrl
          lawnbotEmployee
          marketingSource
          callStatusCode
          callActionReason
          crmNewRecordStatusScode
          crmQuoteStatusCode
          crmSaleStatusCode
          pestPacTenantId
          crmCompanyId
          crmRoute
          crmDayCode
          crmHandheld
          dashboardUsername
          dashboardGreetingName
          dashboardEmailAddress
          paymentType
          termsOfService
          onboardingJson
          serviceAgreementContent
          noServiceAgreementContent
          serviceAgreementTellMeMore
          emergencyTellMeMore
          gettingStartedText
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      bots {
        items {
          version
          id
          botCompanyId
          botTitle
          botName
          botType
          botVersion
          botStatus
          isLive
          botQuoteAnalyzingGif
          sqFtPercentage
          sqftEstimateSource
          zillowFallback
          learnMore
          objections
          preModal
          serviceQuestions
          lookupZipCodeTax
          zipCodes
          dontTaxZipCodes
          botSpeed
          willRedirect
          redirectUrl
          skipProbingQuestions
          greeting
          salutation
          hideSummary
          isMetric
          measurementMode
          hideArielAndStreetView
          customSchedulingVerbiage
          forceKey
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      programs {
        items {
          version
          id
          programCompanyId
          isDeleted
          programType
          programName
          programDescription
          programStartMonth
          programEndMonth
          hideDiscountText
          programProbingQuestionsId
          probingQuestionsJson
          active
          isUpsell
          excludedZips
          productOffering
          conditions
          alwaysOnTop
          alwaysAvailable
          crmProgamCode
          seasonalText
          usesUpfrontPricing
          usesGoogleSheetPricing
          googleSheetSheetId
          googleSheetTabName
          googleSheetRangeMapping
          googleSheetResultsMapping
          upfrontPricingTable
          recurringPricingTable
          alternativeApplicationString
          useProbingQuestions
          useRealGreenPricingEngine
          realGreenPrepayDiscountCode
          realGreenApplicationDiscountCode
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      priceTables {
        items {
          version
          priceTableCompanyId
          id
          isDeleted
          year
          name
          prePayDiscount
          prePayCutoff
          serviceType
          conditions
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteCompanyVersioned = /* GraphQL */ `
  mutation DeleteCompanyVersioned(
    $input: DeleteCompanyVersionedInput!
    $condition: ModelCompanyVersionedConditionInput
  ) {
    deleteCompanyVersioned(input: $input, condition: $condition) {
      version
      id
      approved
      approvedBy
      approvedOn
      companyName
      customerName
      customerEmail
      customerPhone
      customerAddress
      customerCity
      customerState
      customerZip
      companyBrandingId
      companyDataServicesId
      currency
      serviceTax
      maxQuotableSize
      minQuotableSize
      autoPayAvailable
      prePayAvailable
      autoPayAlternativeLabel
      autoPayPriceMethod
      taxOverride
      autoPayMonthlyPricePeriod
      bypassPayment
      crmSolution
      crmPortalUrl
      lawnbotEmployee
      marketingSource
      callStatusCode
      callActionReason
      crmNewRecordStatusScode
      crmQuoteStatusCode
      crmSaleStatusCode
      pestPacTenantId
      crmCompanyId
      crmRoute
      crmDayCode
      crmHandheld
      dashboardUsername
      dashboardGreetingName
      dashboardEmailAddress
      paymentType
      termsOfService
      onboardingJson
      serviceAgreementContent
      noServiceAgreementContent
      serviceAgreementTellMeMore
      emergencyTellMeMore
      gettingStartedText
      createdAt
      updatedAt
      dataServices {
        id
        stripeTestSecret
        stripeSecret
        realGreenAPIKey
        serviceAssistantId
        zillowAPIKey
        airTableBase
        companyDataServicesCompanyId
        airTableTableName
        googleSheetsClientEmail
        googleSheetsPrivateKey
        servmanApiKey
        servmanApiAuth
        servmanApiUrl
        createdAt
        updatedAt
        company {
          version
          id
          approved
          approvedBy
          approvedOn
          companyName
          customerName
          customerEmail
          customerPhone
          customerAddress
          customerCity
          customerState
          customerZip
          companyBrandingId
          companyDataServicesId
          currency
          serviceTax
          maxQuotableSize
          minQuotableSize
          autoPayAvailable
          prePayAvailable
          autoPayAlternativeLabel
          autoPayPriceMethod
          taxOverride
          autoPayMonthlyPricePeriod
          bypassPayment
          crmSolution
          crmPortalUrl
          lawnbotEmployee
          marketingSource
          callStatusCode
          callActionReason
          crmNewRecordStatusScode
          crmQuoteStatusCode
          crmSaleStatusCode
          pestPacTenantId
          crmCompanyId
          crmRoute
          crmDayCode
          crmHandheld
          dashboardUsername
          dashboardGreetingName
          dashboardEmailAddress
          paymentType
          termsOfService
          onboardingJson
          serviceAgreementContent
          noServiceAgreementContent
          serviceAgreementTellMeMore
          emergencyTellMeMore
          gettingStartedText
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      offers {
        items {
          id
          name
          startDate
          endDate
          programs
          offerCompanyId
          active
          hide
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      branding {
        version
        id
        companyBrandingCompanyId
        instaQuoteMessage
        companyLogo
        companyAvatar
        tagLine
        valueAddImage
        valueAddDescription
        aboutUsAssetUrl
        aboutUsAssetType
        quoteModalImage
        createdAt
        updatedAt
        company {
          version
          id
          approved
          approvedBy
          approvedOn
          companyName
          customerName
          customerEmail
          customerPhone
          customerAddress
          customerCity
          customerState
          customerZip
          companyBrandingId
          companyDataServicesId
          currency
          serviceTax
          maxQuotableSize
          minQuotableSize
          autoPayAvailable
          prePayAvailable
          autoPayAlternativeLabel
          autoPayPriceMethod
          taxOverride
          autoPayMonthlyPricePeriod
          bypassPayment
          crmSolution
          crmPortalUrl
          lawnbotEmployee
          marketingSource
          callStatusCode
          callActionReason
          crmNewRecordStatusScode
          crmQuoteStatusCode
          crmSaleStatusCode
          pestPacTenantId
          crmCompanyId
          crmRoute
          crmDayCode
          crmHandheld
          dashboardUsername
          dashboardGreetingName
          dashboardEmailAddress
          paymentType
          termsOfService
          onboardingJson
          serviceAgreementContent
          noServiceAgreementContent
          serviceAgreementTellMeMore
          emergencyTellMeMore
          gettingStartedText
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      bots {
        items {
          version
          id
          botCompanyId
          botTitle
          botName
          botType
          botVersion
          botStatus
          isLive
          botQuoteAnalyzingGif
          sqFtPercentage
          sqftEstimateSource
          zillowFallback
          learnMore
          objections
          preModal
          serviceQuestions
          lookupZipCodeTax
          zipCodes
          dontTaxZipCodes
          botSpeed
          willRedirect
          redirectUrl
          skipProbingQuestions
          greeting
          salutation
          hideSummary
          isMetric
          measurementMode
          hideArielAndStreetView
          customSchedulingVerbiage
          forceKey
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      programs {
        items {
          version
          id
          programCompanyId
          isDeleted
          programType
          programName
          programDescription
          programStartMonth
          programEndMonth
          hideDiscountText
          programProbingQuestionsId
          probingQuestionsJson
          active
          isUpsell
          excludedZips
          productOffering
          conditions
          alwaysOnTop
          alwaysAvailable
          crmProgamCode
          seasonalText
          usesUpfrontPricing
          usesGoogleSheetPricing
          googleSheetSheetId
          googleSheetTabName
          googleSheetRangeMapping
          googleSheetResultsMapping
          upfrontPricingTable
          recurringPricingTable
          alternativeApplicationString
          useProbingQuestions
          useRealGreenPricingEngine
          realGreenPrepayDiscountCode
          realGreenApplicationDiscountCode
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      priceTables {
        items {
          version
          priceTableCompanyId
          id
          isDeleted
          year
          name
          prePayDiscount
          prePayCutoff
          serviceType
          conditions
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createCompanyBrandingVersioned = /* GraphQL */ `
  mutation CreateCompanyBrandingVersioned(
    $input: CreateCompanyBrandingVersionedInput!
    $condition: ModelCompanyBrandingVersionedConditionInput
  ) {
    createCompanyBrandingVersioned(input: $input, condition: $condition) {
      version
      id
      companyBrandingCompanyId
      instaQuoteMessage
      companyLogo
      companyAvatar
      tagLine
      valueAddImage
      valueAddDescription
      aboutUsAssetUrl
      aboutUsAssetType
      quoteModalImage
      createdAt
      updatedAt
      company {
        version
        id
        approved
        approvedBy
        approvedOn
        companyName
        customerName
        customerEmail
        customerPhone
        customerAddress
        customerCity
        customerState
        customerZip
        companyBrandingId
        companyDataServicesId
        currency
        serviceTax
        maxQuotableSize
        minQuotableSize
        autoPayAvailable
        prePayAvailable
        autoPayAlternativeLabel
        autoPayPriceMethod
        taxOverride
        autoPayMonthlyPricePeriod
        bypassPayment
        crmSolution
        crmPortalUrl
        lawnbotEmployee
        marketingSource
        callStatusCode
        callActionReason
        crmNewRecordStatusScode
        crmQuoteStatusCode
        crmSaleStatusCode
        pestPacTenantId
        crmCompanyId
        crmRoute
        crmDayCode
        crmHandheld
        dashboardUsername
        dashboardGreetingName
        dashboardEmailAddress
        paymentType
        termsOfService
        onboardingJson
        serviceAgreementContent
        noServiceAgreementContent
        serviceAgreementTellMeMore
        emergencyTellMeMore
        gettingStartedText
        createdAt
        updatedAt
        dataServices {
          id
          stripeTestSecret
          stripeSecret
          realGreenAPIKey
          serviceAssistantId
          zillowAPIKey
          airTableBase
          companyDataServicesCompanyId
          airTableTableName
          googleSheetsClientEmail
          googleSheetsPrivateKey
          servmanApiKey
          servmanApiAuth
          servmanApiUrl
          createdAt
          updatedAt
          __typename
        }
        offers {
          nextToken
          __typename
        }
        branding {
          version
          id
          companyBrandingCompanyId
          instaQuoteMessage
          companyLogo
          companyAvatar
          tagLine
          valueAddImage
          valueAddDescription
          aboutUsAssetUrl
          aboutUsAssetType
          quoteModalImage
          createdAt
          updatedAt
          __typename
        }
        bots {
          nextToken
          __typename
        }
        programs {
          nextToken
          __typename
        }
        priceTables {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const updateCompanyBrandingVersioned = /* GraphQL */ `
  mutation UpdateCompanyBrandingVersioned(
    $input: UpdateCompanyBrandingVersionedInput!
    $condition: ModelCompanyBrandingVersionedConditionInput
  ) {
    updateCompanyBrandingVersioned(input: $input, condition: $condition) {
      version
      id
      companyBrandingCompanyId
      instaQuoteMessage
      companyLogo
      companyAvatar
      tagLine
      valueAddImage
      valueAddDescription
      aboutUsAssetUrl
      aboutUsAssetType
      quoteModalImage
      createdAt
      updatedAt
      company {
        version
        id
        approved
        approvedBy
        approvedOn
        companyName
        customerName
        customerEmail
        customerPhone
        customerAddress
        customerCity
        customerState
        customerZip
        companyBrandingId
        companyDataServicesId
        currency
        serviceTax
        maxQuotableSize
        minQuotableSize
        autoPayAvailable
        prePayAvailable
        autoPayAlternativeLabel
        autoPayPriceMethod
        taxOverride
        autoPayMonthlyPricePeriod
        bypassPayment
        crmSolution
        crmPortalUrl
        lawnbotEmployee
        marketingSource
        callStatusCode
        callActionReason
        crmNewRecordStatusScode
        crmQuoteStatusCode
        crmSaleStatusCode
        pestPacTenantId
        crmCompanyId
        crmRoute
        crmDayCode
        crmHandheld
        dashboardUsername
        dashboardGreetingName
        dashboardEmailAddress
        paymentType
        termsOfService
        onboardingJson
        serviceAgreementContent
        noServiceAgreementContent
        serviceAgreementTellMeMore
        emergencyTellMeMore
        gettingStartedText
        createdAt
        updatedAt
        dataServices {
          id
          stripeTestSecret
          stripeSecret
          realGreenAPIKey
          serviceAssistantId
          zillowAPIKey
          airTableBase
          companyDataServicesCompanyId
          airTableTableName
          googleSheetsClientEmail
          googleSheetsPrivateKey
          servmanApiKey
          servmanApiAuth
          servmanApiUrl
          createdAt
          updatedAt
          __typename
        }
        offers {
          nextToken
          __typename
        }
        branding {
          version
          id
          companyBrandingCompanyId
          instaQuoteMessage
          companyLogo
          companyAvatar
          tagLine
          valueAddImage
          valueAddDescription
          aboutUsAssetUrl
          aboutUsAssetType
          quoteModalImage
          createdAt
          updatedAt
          __typename
        }
        bots {
          nextToken
          __typename
        }
        programs {
          nextToken
          __typename
        }
        priceTables {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const deleteCompanyBrandingVersioned = /* GraphQL */ `
  mutation DeleteCompanyBrandingVersioned(
    $input: DeleteCompanyBrandingVersionedInput!
    $condition: ModelCompanyBrandingVersionedConditionInput
  ) {
    deleteCompanyBrandingVersioned(input: $input, condition: $condition) {
      version
      id
      companyBrandingCompanyId
      instaQuoteMessage
      companyLogo
      companyAvatar
      tagLine
      valueAddImage
      valueAddDescription
      aboutUsAssetUrl
      aboutUsAssetType
      quoteModalImage
      createdAt
      updatedAt
      company {
        version
        id
        approved
        approvedBy
        approvedOn
        companyName
        customerName
        customerEmail
        customerPhone
        customerAddress
        customerCity
        customerState
        customerZip
        companyBrandingId
        companyDataServicesId
        currency
        serviceTax
        maxQuotableSize
        minQuotableSize
        autoPayAvailable
        prePayAvailable
        autoPayAlternativeLabel
        autoPayPriceMethod
        taxOverride
        autoPayMonthlyPricePeriod
        bypassPayment
        crmSolution
        crmPortalUrl
        lawnbotEmployee
        marketingSource
        callStatusCode
        callActionReason
        crmNewRecordStatusScode
        crmQuoteStatusCode
        crmSaleStatusCode
        pestPacTenantId
        crmCompanyId
        crmRoute
        crmDayCode
        crmHandheld
        dashboardUsername
        dashboardGreetingName
        dashboardEmailAddress
        paymentType
        termsOfService
        onboardingJson
        serviceAgreementContent
        noServiceAgreementContent
        serviceAgreementTellMeMore
        emergencyTellMeMore
        gettingStartedText
        createdAt
        updatedAt
        dataServices {
          id
          stripeTestSecret
          stripeSecret
          realGreenAPIKey
          serviceAssistantId
          zillowAPIKey
          airTableBase
          companyDataServicesCompanyId
          airTableTableName
          googleSheetsClientEmail
          googleSheetsPrivateKey
          servmanApiKey
          servmanApiAuth
          servmanApiUrl
          createdAt
          updatedAt
          __typename
        }
        offers {
          nextToken
          __typename
        }
        branding {
          version
          id
          companyBrandingCompanyId
          instaQuoteMessage
          companyLogo
          companyAvatar
          tagLine
          valueAddImage
          valueAddDescription
          aboutUsAssetUrl
          aboutUsAssetType
          quoteModalImage
          createdAt
          updatedAt
          __typename
        }
        bots {
          nextToken
          __typename
        }
        programs {
          nextToken
          __typename
        }
        priceTables {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const createBotVersioned = /* GraphQL */ `
  mutation CreateBotVersioned(
    $input: CreateBotVersionedInput!
    $condition: ModelBotVersionedConditionInput
  ) {
    createBotVersioned(input: $input, condition: $condition) {
      version
      id
      botCompanyId
      botTitle
      botName
      botType
      botVersion
      botStatus
      isLive
      botQuoteAnalyzingGif
      sqFtPercentage
      sqftEstimateSource
      zillowFallback
      learnMore
      objections
      preModal
      serviceQuestions
      lookupZipCodeTax
      zipCodes
      dontTaxZipCodes
      botSpeed
      willRedirect
      redirectUrl
      skipProbingQuestions
      greeting
      salutation
      hideSummary
      isMetric
      measurementMode
      hideArielAndStreetView
      customSchedulingVerbiage
      forceKey
      createdAt
      updatedAt
      sections {
        items {
          id
          sectionName
          entryId
          nextSectionId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      company {
        version
        id
        approved
        approvedBy
        approvedOn
        companyName
        customerName
        customerEmail
        customerPhone
        customerAddress
        customerCity
        customerState
        customerZip
        companyBrandingId
        companyDataServicesId
        currency
        serviceTax
        maxQuotableSize
        minQuotableSize
        autoPayAvailable
        prePayAvailable
        autoPayAlternativeLabel
        autoPayPriceMethod
        taxOverride
        autoPayMonthlyPricePeriod
        bypassPayment
        crmSolution
        crmPortalUrl
        lawnbotEmployee
        marketingSource
        callStatusCode
        callActionReason
        crmNewRecordStatusScode
        crmQuoteStatusCode
        crmSaleStatusCode
        pestPacTenantId
        crmCompanyId
        crmRoute
        crmDayCode
        crmHandheld
        dashboardUsername
        dashboardGreetingName
        dashboardEmailAddress
        paymentType
        termsOfService
        onboardingJson
        serviceAgreementContent
        noServiceAgreementContent
        serviceAgreementTellMeMore
        emergencyTellMeMore
        gettingStartedText
        createdAt
        updatedAt
        dataServices {
          id
          stripeTestSecret
          stripeSecret
          realGreenAPIKey
          serviceAssistantId
          zillowAPIKey
          airTableBase
          companyDataServicesCompanyId
          airTableTableName
          googleSheetsClientEmail
          googleSheetsPrivateKey
          servmanApiKey
          servmanApiAuth
          servmanApiUrl
          createdAt
          updatedAt
          __typename
        }
        offers {
          nextToken
          __typename
        }
        branding {
          version
          id
          companyBrandingCompanyId
          instaQuoteMessage
          companyLogo
          companyAvatar
          tagLine
          valueAddImage
          valueAddDescription
          aboutUsAssetUrl
          aboutUsAssetType
          quoteModalImage
          createdAt
          updatedAt
          __typename
        }
        bots {
          nextToken
          __typename
        }
        programs {
          nextToken
          __typename
        }
        priceTables {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const updateBotVersioned = /* GraphQL */ `
  mutation UpdateBotVersioned(
    $input: UpdateBotVersionedInput!
    $condition: ModelBotVersionedConditionInput
  ) {
    updateBotVersioned(input: $input, condition: $condition) {
      version
      id
      botCompanyId
      botTitle
      botName
      botType
      botVersion
      botStatus
      isLive
      botQuoteAnalyzingGif
      sqFtPercentage
      sqftEstimateSource
      zillowFallback
      learnMore
      objections
      preModal
      serviceQuestions
      lookupZipCodeTax
      zipCodes
      dontTaxZipCodes
      botSpeed
      willRedirect
      redirectUrl
      skipProbingQuestions
      greeting
      salutation
      hideSummary
      isMetric
      measurementMode
      hideArielAndStreetView
      customSchedulingVerbiage
      forceKey
      createdAt
      updatedAt
      sections {
        items {
          id
          sectionName
          entryId
          nextSectionId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      company {
        version
        id
        approved
        approvedBy
        approvedOn
        companyName
        customerName
        customerEmail
        customerPhone
        customerAddress
        customerCity
        customerState
        customerZip
        companyBrandingId
        companyDataServicesId
        currency
        serviceTax
        maxQuotableSize
        minQuotableSize
        autoPayAvailable
        prePayAvailable
        autoPayAlternativeLabel
        autoPayPriceMethod
        taxOverride
        autoPayMonthlyPricePeriod
        bypassPayment
        crmSolution
        crmPortalUrl
        lawnbotEmployee
        marketingSource
        callStatusCode
        callActionReason
        crmNewRecordStatusScode
        crmQuoteStatusCode
        crmSaleStatusCode
        pestPacTenantId
        crmCompanyId
        crmRoute
        crmDayCode
        crmHandheld
        dashboardUsername
        dashboardGreetingName
        dashboardEmailAddress
        paymentType
        termsOfService
        onboardingJson
        serviceAgreementContent
        noServiceAgreementContent
        serviceAgreementTellMeMore
        emergencyTellMeMore
        gettingStartedText
        createdAt
        updatedAt
        dataServices {
          id
          stripeTestSecret
          stripeSecret
          realGreenAPIKey
          serviceAssistantId
          zillowAPIKey
          airTableBase
          companyDataServicesCompanyId
          airTableTableName
          googleSheetsClientEmail
          googleSheetsPrivateKey
          servmanApiKey
          servmanApiAuth
          servmanApiUrl
          createdAt
          updatedAt
          __typename
        }
        offers {
          nextToken
          __typename
        }
        branding {
          version
          id
          companyBrandingCompanyId
          instaQuoteMessage
          companyLogo
          companyAvatar
          tagLine
          valueAddImage
          valueAddDescription
          aboutUsAssetUrl
          aboutUsAssetType
          quoteModalImage
          createdAt
          updatedAt
          __typename
        }
        bots {
          nextToken
          __typename
        }
        programs {
          nextToken
          __typename
        }
        priceTables {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const deleteBotVersioned = /* GraphQL */ `
  mutation DeleteBotVersioned(
    $input: DeleteBotVersionedInput!
    $condition: ModelBotVersionedConditionInput
  ) {
    deleteBotVersioned(input: $input, condition: $condition) {
      version
      id
      botCompanyId
      botTitle
      botName
      botType
      botVersion
      botStatus
      isLive
      botQuoteAnalyzingGif
      sqFtPercentage
      sqftEstimateSource
      zillowFallback
      learnMore
      objections
      preModal
      serviceQuestions
      lookupZipCodeTax
      zipCodes
      dontTaxZipCodes
      botSpeed
      willRedirect
      redirectUrl
      skipProbingQuestions
      greeting
      salutation
      hideSummary
      isMetric
      measurementMode
      hideArielAndStreetView
      customSchedulingVerbiage
      forceKey
      createdAt
      updatedAt
      sections {
        items {
          id
          sectionName
          entryId
          nextSectionId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      company {
        version
        id
        approved
        approvedBy
        approvedOn
        companyName
        customerName
        customerEmail
        customerPhone
        customerAddress
        customerCity
        customerState
        customerZip
        companyBrandingId
        companyDataServicesId
        currency
        serviceTax
        maxQuotableSize
        minQuotableSize
        autoPayAvailable
        prePayAvailable
        autoPayAlternativeLabel
        autoPayPriceMethod
        taxOverride
        autoPayMonthlyPricePeriod
        bypassPayment
        crmSolution
        crmPortalUrl
        lawnbotEmployee
        marketingSource
        callStatusCode
        callActionReason
        crmNewRecordStatusScode
        crmQuoteStatusCode
        crmSaleStatusCode
        pestPacTenantId
        crmCompanyId
        crmRoute
        crmDayCode
        crmHandheld
        dashboardUsername
        dashboardGreetingName
        dashboardEmailAddress
        paymentType
        termsOfService
        onboardingJson
        serviceAgreementContent
        noServiceAgreementContent
        serviceAgreementTellMeMore
        emergencyTellMeMore
        gettingStartedText
        createdAt
        updatedAt
        dataServices {
          id
          stripeTestSecret
          stripeSecret
          realGreenAPIKey
          serviceAssistantId
          zillowAPIKey
          airTableBase
          companyDataServicesCompanyId
          airTableTableName
          googleSheetsClientEmail
          googleSheetsPrivateKey
          servmanApiKey
          servmanApiAuth
          servmanApiUrl
          createdAt
          updatedAt
          __typename
        }
        offers {
          nextToken
          __typename
        }
        branding {
          version
          id
          companyBrandingCompanyId
          instaQuoteMessage
          companyLogo
          companyAvatar
          tagLine
          valueAddImage
          valueAddDescription
          aboutUsAssetUrl
          aboutUsAssetType
          quoteModalImage
          createdAt
          updatedAt
          __typename
        }
        bots {
          nextToken
          __typename
        }
        programs {
          nextToken
          __typename
        }
        priceTables {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const createProgramVersioned = /* GraphQL */ `
  mutation CreateProgramVersioned(
    $input: CreateProgramVersionedInput!
    $condition: ModelProgramVersionedConditionInput
  ) {
    createProgramVersioned(input: $input, condition: $condition) {
      version
      id
      programCompanyId
      isDeleted
      programType
      programName
      programDescription
      programStartMonth
      programEndMonth
      hideDiscountText
      programProbingQuestionsId
      probingQuestionsJson
      active
      isUpsell
      excludedZips
      productOffering
      conditions
      alwaysOnTop
      alwaysAvailable
      crmProgamCode
      seasonalText
      usesUpfrontPricing
      usesGoogleSheetPricing
      googleSheetSheetId
      googleSheetTabName
      googleSheetRangeMapping
      googleSheetResultsMapping
      upfrontPricingTable
      recurringPricingTable
      alternativeApplicationString
      useProbingQuestions
      useRealGreenPricingEngine
      realGreenPrepayDiscountCode
      realGreenApplicationDiscountCode
      createdAt
      updatedAt
      probingQuestions {
        id
        probingQuestionsProgramId
        sectionName
        entryId
        nextSectionId
        steps {
          id
          stepName
          optionType
          message
          end
          asMessage
          waitAction
          trigger
          createdAt
          updatedAt
          __typename
        }
        conditionals {
          id
          matches
          trigger
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        program {
          version
          id
          programCompanyId
          isDeleted
          programType
          programName
          programDescription
          programStartMonth
          programEndMonth
          hideDiscountText
          programProbingQuestionsId
          probingQuestionsJson
          active
          isUpsell
          excludedZips
          productOffering
          conditions
          alwaysOnTop
          alwaysAvailable
          crmProgamCode
          seasonalText
          usesUpfrontPricing
          usesGoogleSheetPricing
          googleSheetSheetId
          googleSheetTabName
          googleSheetRangeMapping
          googleSheetResultsMapping
          upfrontPricingTable
          recurringPricingTable
          alternativeApplicationString
          useProbingQuestions
          useRealGreenPricingEngine
          realGreenPrepayDiscountCode
          realGreenApplicationDiscountCode
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      company {
        version
        id
        approved
        approvedBy
        approvedOn
        companyName
        customerName
        customerEmail
        customerPhone
        customerAddress
        customerCity
        customerState
        customerZip
        companyBrandingId
        companyDataServicesId
        currency
        serviceTax
        maxQuotableSize
        minQuotableSize
        autoPayAvailable
        prePayAvailable
        autoPayAlternativeLabel
        autoPayPriceMethod
        taxOverride
        autoPayMonthlyPricePeriod
        bypassPayment
        crmSolution
        crmPortalUrl
        lawnbotEmployee
        marketingSource
        callStatusCode
        callActionReason
        crmNewRecordStatusScode
        crmQuoteStatusCode
        crmSaleStatusCode
        pestPacTenantId
        crmCompanyId
        crmRoute
        crmDayCode
        crmHandheld
        dashboardUsername
        dashboardGreetingName
        dashboardEmailAddress
        paymentType
        termsOfService
        onboardingJson
        serviceAgreementContent
        noServiceAgreementContent
        serviceAgreementTellMeMore
        emergencyTellMeMore
        gettingStartedText
        createdAt
        updatedAt
        dataServices {
          id
          stripeTestSecret
          stripeSecret
          realGreenAPIKey
          serviceAssistantId
          zillowAPIKey
          airTableBase
          companyDataServicesCompanyId
          airTableTableName
          googleSheetsClientEmail
          googleSheetsPrivateKey
          servmanApiKey
          servmanApiAuth
          servmanApiUrl
          createdAt
          updatedAt
          __typename
        }
        offers {
          nextToken
          __typename
        }
        branding {
          version
          id
          companyBrandingCompanyId
          instaQuoteMessage
          companyLogo
          companyAvatar
          tagLine
          valueAddImage
          valueAddDescription
          aboutUsAssetUrl
          aboutUsAssetType
          quoteModalImage
          createdAt
          updatedAt
          __typename
        }
        bots {
          nextToken
          __typename
        }
        programs {
          nextToken
          __typename
        }
        priceTables {
          nextToken
          __typename
        }
        __typename
      }
      services {
        items {
          version
          id
          isDeleted
          serviceProgramId
          serviceName
          roundNumber
          priceTableId
          cutOffDate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateProgramVersioned = /* GraphQL */ `
  mutation UpdateProgramVersioned(
    $input: UpdateProgramVersionedInput!
    $condition: ModelProgramVersionedConditionInput
  ) {
    updateProgramVersioned(input: $input, condition: $condition) {
      version
      id
      programCompanyId
      isDeleted
      programType
      programName
      programDescription
      programStartMonth
      programEndMonth
      hideDiscountText
      programProbingQuestionsId
      probingQuestionsJson
      active
      isUpsell
      excludedZips
      productOffering
      conditions
      alwaysOnTop
      alwaysAvailable
      crmProgamCode
      seasonalText
      usesUpfrontPricing
      usesGoogleSheetPricing
      googleSheetSheetId
      googleSheetTabName
      googleSheetRangeMapping
      googleSheetResultsMapping
      upfrontPricingTable
      recurringPricingTable
      alternativeApplicationString
      useProbingQuestions
      useRealGreenPricingEngine
      realGreenPrepayDiscountCode
      realGreenApplicationDiscountCode
      createdAt
      updatedAt
      probingQuestions {
        id
        probingQuestionsProgramId
        sectionName
        entryId
        nextSectionId
        steps {
          id
          stepName
          optionType
          message
          end
          asMessage
          waitAction
          trigger
          createdAt
          updatedAt
          __typename
        }
        conditionals {
          id
          matches
          trigger
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        program {
          version
          id
          programCompanyId
          isDeleted
          programType
          programName
          programDescription
          programStartMonth
          programEndMonth
          hideDiscountText
          programProbingQuestionsId
          probingQuestionsJson
          active
          isUpsell
          excludedZips
          productOffering
          conditions
          alwaysOnTop
          alwaysAvailable
          crmProgamCode
          seasonalText
          usesUpfrontPricing
          usesGoogleSheetPricing
          googleSheetSheetId
          googleSheetTabName
          googleSheetRangeMapping
          googleSheetResultsMapping
          upfrontPricingTable
          recurringPricingTable
          alternativeApplicationString
          useProbingQuestions
          useRealGreenPricingEngine
          realGreenPrepayDiscountCode
          realGreenApplicationDiscountCode
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      company {
        version
        id
        approved
        approvedBy
        approvedOn
        companyName
        customerName
        customerEmail
        customerPhone
        customerAddress
        customerCity
        customerState
        customerZip
        companyBrandingId
        companyDataServicesId
        currency
        serviceTax
        maxQuotableSize
        minQuotableSize
        autoPayAvailable
        prePayAvailable
        autoPayAlternativeLabel
        autoPayPriceMethod
        taxOverride
        autoPayMonthlyPricePeriod
        bypassPayment
        crmSolution
        crmPortalUrl
        lawnbotEmployee
        marketingSource
        callStatusCode
        callActionReason
        crmNewRecordStatusScode
        crmQuoteStatusCode
        crmSaleStatusCode
        pestPacTenantId
        crmCompanyId
        crmRoute
        crmDayCode
        crmHandheld
        dashboardUsername
        dashboardGreetingName
        dashboardEmailAddress
        paymentType
        termsOfService
        onboardingJson
        serviceAgreementContent
        noServiceAgreementContent
        serviceAgreementTellMeMore
        emergencyTellMeMore
        gettingStartedText
        createdAt
        updatedAt
        dataServices {
          id
          stripeTestSecret
          stripeSecret
          realGreenAPIKey
          serviceAssistantId
          zillowAPIKey
          airTableBase
          companyDataServicesCompanyId
          airTableTableName
          googleSheetsClientEmail
          googleSheetsPrivateKey
          servmanApiKey
          servmanApiAuth
          servmanApiUrl
          createdAt
          updatedAt
          __typename
        }
        offers {
          nextToken
          __typename
        }
        branding {
          version
          id
          companyBrandingCompanyId
          instaQuoteMessage
          companyLogo
          companyAvatar
          tagLine
          valueAddImage
          valueAddDescription
          aboutUsAssetUrl
          aboutUsAssetType
          quoteModalImage
          createdAt
          updatedAt
          __typename
        }
        bots {
          nextToken
          __typename
        }
        programs {
          nextToken
          __typename
        }
        priceTables {
          nextToken
          __typename
        }
        __typename
      }
      services {
        items {
          version
          id
          isDeleted
          serviceProgramId
          serviceName
          roundNumber
          priceTableId
          cutOffDate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteProgramVersioned = /* GraphQL */ `
  mutation DeleteProgramVersioned(
    $input: DeleteProgramVersionedInput!
    $condition: ModelProgramVersionedConditionInput
  ) {
    deleteProgramVersioned(input: $input, condition: $condition) {
      version
      id
      programCompanyId
      isDeleted
      programType
      programName
      programDescription
      programStartMonth
      programEndMonth
      hideDiscountText
      programProbingQuestionsId
      probingQuestionsJson
      active
      isUpsell
      excludedZips
      productOffering
      conditions
      alwaysOnTop
      alwaysAvailable
      crmProgamCode
      seasonalText
      usesUpfrontPricing
      usesGoogleSheetPricing
      googleSheetSheetId
      googleSheetTabName
      googleSheetRangeMapping
      googleSheetResultsMapping
      upfrontPricingTable
      recurringPricingTable
      alternativeApplicationString
      useProbingQuestions
      useRealGreenPricingEngine
      realGreenPrepayDiscountCode
      realGreenApplicationDiscountCode
      createdAt
      updatedAt
      probingQuestions {
        id
        probingQuestionsProgramId
        sectionName
        entryId
        nextSectionId
        steps {
          id
          stepName
          optionType
          message
          end
          asMessage
          waitAction
          trigger
          createdAt
          updatedAt
          __typename
        }
        conditionals {
          id
          matches
          trigger
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        program {
          version
          id
          programCompanyId
          isDeleted
          programType
          programName
          programDescription
          programStartMonth
          programEndMonth
          hideDiscountText
          programProbingQuestionsId
          probingQuestionsJson
          active
          isUpsell
          excludedZips
          productOffering
          conditions
          alwaysOnTop
          alwaysAvailable
          crmProgamCode
          seasonalText
          usesUpfrontPricing
          usesGoogleSheetPricing
          googleSheetSheetId
          googleSheetTabName
          googleSheetRangeMapping
          googleSheetResultsMapping
          upfrontPricingTable
          recurringPricingTable
          alternativeApplicationString
          useProbingQuestions
          useRealGreenPricingEngine
          realGreenPrepayDiscountCode
          realGreenApplicationDiscountCode
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      company {
        version
        id
        approved
        approvedBy
        approvedOn
        companyName
        customerName
        customerEmail
        customerPhone
        customerAddress
        customerCity
        customerState
        customerZip
        companyBrandingId
        companyDataServicesId
        currency
        serviceTax
        maxQuotableSize
        minQuotableSize
        autoPayAvailable
        prePayAvailable
        autoPayAlternativeLabel
        autoPayPriceMethod
        taxOverride
        autoPayMonthlyPricePeriod
        bypassPayment
        crmSolution
        crmPortalUrl
        lawnbotEmployee
        marketingSource
        callStatusCode
        callActionReason
        crmNewRecordStatusScode
        crmQuoteStatusCode
        crmSaleStatusCode
        pestPacTenantId
        crmCompanyId
        crmRoute
        crmDayCode
        crmHandheld
        dashboardUsername
        dashboardGreetingName
        dashboardEmailAddress
        paymentType
        termsOfService
        onboardingJson
        serviceAgreementContent
        noServiceAgreementContent
        serviceAgreementTellMeMore
        emergencyTellMeMore
        gettingStartedText
        createdAt
        updatedAt
        dataServices {
          id
          stripeTestSecret
          stripeSecret
          realGreenAPIKey
          serviceAssistantId
          zillowAPIKey
          airTableBase
          companyDataServicesCompanyId
          airTableTableName
          googleSheetsClientEmail
          googleSheetsPrivateKey
          servmanApiKey
          servmanApiAuth
          servmanApiUrl
          createdAt
          updatedAt
          __typename
        }
        offers {
          nextToken
          __typename
        }
        branding {
          version
          id
          companyBrandingCompanyId
          instaQuoteMessage
          companyLogo
          companyAvatar
          tagLine
          valueAddImage
          valueAddDescription
          aboutUsAssetUrl
          aboutUsAssetType
          quoteModalImage
          createdAt
          updatedAt
          __typename
        }
        bots {
          nextToken
          __typename
        }
        programs {
          nextToken
          __typename
        }
        priceTables {
          nextToken
          __typename
        }
        __typename
      }
      services {
        items {
          version
          id
          isDeleted
          serviceProgramId
          serviceName
          roundNumber
          priceTableId
          cutOffDate
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createServiceVersioned = /* GraphQL */ `
  mutation CreateServiceVersioned(
    $input: CreateServiceVersionedInput!
    $condition: ModelServiceVersionedConditionInput
  ) {
    createServiceVersioned(input: $input, condition: $condition) {
      version
      id
      isDeleted
      serviceProgramId
      serviceName
      roundNumber
      priceTableId
      cutOffDate
      createdAt
      updatedAt
      program {
        version
        id
        programCompanyId
        isDeleted
        programType
        programName
        programDescription
        programStartMonth
        programEndMonth
        hideDiscountText
        programProbingQuestionsId
        probingQuestionsJson
        active
        isUpsell
        excludedZips
        productOffering
        conditions
        alwaysOnTop
        alwaysAvailable
        crmProgamCode
        seasonalText
        usesUpfrontPricing
        usesGoogleSheetPricing
        googleSheetSheetId
        googleSheetTabName
        googleSheetRangeMapping
        googleSheetResultsMapping
        upfrontPricingTable
        recurringPricingTable
        alternativeApplicationString
        useProbingQuestions
        useRealGreenPricingEngine
        realGreenPrepayDiscountCode
        realGreenApplicationDiscountCode
        createdAt
        updatedAt
        probingQuestions {
          id
          probingQuestionsProgramId
          sectionName
          entryId
          nextSectionId
          createdAt
          updatedAt
          __typename
        }
        company {
          version
          id
          approved
          approvedBy
          approvedOn
          companyName
          customerName
          customerEmail
          customerPhone
          customerAddress
          customerCity
          customerState
          customerZip
          companyBrandingId
          companyDataServicesId
          currency
          serviceTax
          maxQuotableSize
          minQuotableSize
          autoPayAvailable
          prePayAvailable
          autoPayAlternativeLabel
          autoPayPriceMethod
          taxOverride
          autoPayMonthlyPricePeriod
          bypassPayment
          crmSolution
          crmPortalUrl
          lawnbotEmployee
          marketingSource
          callStatusCode
          callActionReason
          crmNewRecordStatusScode
          crmQuoteStatusCode
          crmSaleStatusCode
          pestPacTenantId
          crmCompanyId
          crmRoute
          crmDayCode
          crmHandheld
          dashboardUsername
          dashboardGreetingName
          dashboardEmailAddress
          paymentType
          termsOfService
          onboardingJson
          serviceAgreementContent
          noServiceAgreementContent
          serviceAgreementTellMeMore
          emergencyTellMeMore
          gettingStartedText
          createdAt
          updatedAt
          __typename
        }
        services {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const updateServiceVersioned = /* GraphQL */ `
  mutation UpdateServiceVersioned(
    $input: UpdateServiceVersionedInput!
    $condition: ModelServiceVersionedConditionInput
  ) {
    updateServiceVersioned(input: $input, condition: $condition) {
      version
      id
      isDeleted
      serviceProgramId
      serviceName
      roundNumber
      priceTableId
      cutOffDate
      createdAt
      updatedAt
      program {
        version
        id
        programCompanyId
        isDeleted
        programType
        programName
        programDescription
        programStartMonth
        programEndMonth
        hideDiscountText
        programProbingQuestionsId
        probingQuestionsJson
        active
        isUpsell
        excludedZips
        productOffering
        conditions
        alwaysOnTop
        alwaysAvailable
        crmProgamCode
        seasonalText
        usesUpfrontPricing
        usesGoogleSheetPricing
        googleSheetSheetId
        googleSheetTabName
        googleSheetRangeMapping
        googleSheetResultsMapping
        upfrontPricingTable
        recurringPricingTable
        alternativeApplicationString
        useProbingQuestions
        useRealGreenPricingEngine
        realGreenPrepayDiscountCode
        realGreenApplicationDiscountCode
        createdAt
        updatedAt
        probingQuestions {
          id
          probingQuestionsProgramId
          sectionName
          entryId
          nextSectionId
          createdAt
          updatedAt
          __typename
        }
        company {
          version
          id
          approved
          approvedBy
          approvedOn
          companyName
          customerName
          customerEmail
          customerPhone
          customerAddress
          customerCity
          customerState
          customerZip
          companyBrandingId
          companyDataServicesId
          currency
          serviceTax
          maxQuotableSize
          minQuotableSize
          autoPayAvailable
          prePayAvailable
          autoPayAlternativeLabel
          autoPayPriceMethod
          taxOverride
          autoPayMonthlyPricePeriod
          bypassPayment
          crmSolution
          crmPortalUrl
          lawnbotEmployee
          marketingSource
          callStatusCode
          callActionReason
          crmNewRecordStatusScode
          crmQuoteStatusCode
          crmSaleStatusCode
          pestPacTenantId
          crmCompanyId
          crmRoute
          crmDayCode
          crmHandheld
          dashboardUsername
          dashboardGreetingName
          dashboardEmailAddress
          paymentType
          termsOfService
          onboardingJson
          serviceAgreementContent
          noServiceAgreementContent
          serviceAgreementTellMeMore
          emergencyTellMeMore
          gettingStartedText
          createdAt
          updatedAt
          __typename
        }
        services {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const deleteServiceVersioned = /* GraphQL */ `
  mutation DeleteServiceVersioned(
    $input: DeleteServiceVersionedInput!
    $condition: ModelServiceVersionedConditionInput
  ) {
    deleteServiceVersioned(input: $input, condition: $condition) {
      version
      id
      isDeleted
      serviceProgramId
      serviceName
      roundNumber
      priceTableId
      cutOffDate
      createdAt
      updatedAt
      program {
        version
        id
        programCompanyId
        isDeleted
        programType
        programName
        programDescription
        programStartMonth
        programEndMonth
        hideDiscountText
        programProbingQuestionsId
        probingQuestionsJson
        active
        isUpsell
        excludedZips
        productOffering
        conditions
        alwaysOnTop
        alwaysAvailable
        crmProgamCode
        seasonalText
        usesUpfrontPricing
        usesGoogleSheetPricing
        googleSheetSheetId
        googleSheetTabName
        googleSheetRangeMapping
        googleSheetResultsMapping
        upfrontPricingTable
        recurringPricingTable
        alternativeApplicationString
        useProbingQuestions
        useRealGreenPricingEngine
        realGreenPrepayDiscountCode
        realGreenApplicationDiscountCode
        createdAt
        updatedAt
        probingQuestions {
          id
          probingQuestionsProgramId
          sectionName
          entryId
          nextSectionId
          createdAt
          updatedAt
          __typename
        }
        company {
          version
          id
          approved
          approvedBy
          approvedOn
          companyName
          customerName
          customerEmail
          customerPhone
          customerAddress
          customerCity
          customerState
          customerZip
          companyBrandingId
          companyDataServicesId
          currency
          serviceTax
          maxQuotableSize
          minQuotableSize
          autoPayAvailable
          prePayAvailable
          autoPayAlternativeLabel
          autoPayPriceMethod
          taxOverride
          autoPayMonthlyPricePeriod
          bypassPayment
          crmSolution
          crmPortalUrl
          lawnbotEmployee
          marketingSource
          callStatusCode
          callActionReason
          crmNewRecordStatusScode
          crmQuoteStatusCode
          crmSaleStatusCode
          pestPacTenantId
          crmCompanyId
          crmRoute
          crmDayCode
          crmHandheld
          dashboardUsername
          dashboardGreetingName
          dashboardEmailAddress
          paymentType
          termsOfService
          onboardingJson
          serviceAgreementContent
          noServiceAgreementContent
          serviceAgreementTellMeMore
          emergencyTellMeMore
          gettingStartedText
          createdAt
          updatedAt
          __typename
        }
        services {
          nextToken
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const createPriceTableVersioned = /* GraphQL */ `
  mutation CreatePriceTableVersioned(
    $input: CreatePriceTableVersionedInput!
    $condition: ModelPriceTableVersionedConditionInput
  ) {
    createPriceTableVersioned(input: $input, condition: $condition) {
      version
      priceTableCompanyId
      id
      isDeleted
      year
      name
      prePayDiscount
      prePayCutoff
      serviceType
      conditions
      createdAt
      updatedAt
      company {
        id
        companyName
        customerName
        customerEmail
        customerPhone
        customerAddress
        customerCity
        customerState
        customerZip
        serviceTax
        maxQuotableSize
        minQuotableSize
        autoPayAvailable
        prePayAvailable
        autoPayAlternativeLabel
        autoPayPriceMethod
        autoPayMonthlyPricePeriod
        bypassPayment
        crmSolution
        crmPortalUrl
        lawnbotEmployee
        marketingSource
        callStatusCode
        callActionReason
        crmNewRecordStatusScode
        crmQuoteStatusCode
        crmSaleStatusCode
        crmCompanyId
        crmRoute
        crmDayCode
        crmHandheld
        dashboardUsername
        dashboardGreetingName
        dashboardEmailAddress
        paymentType
        termsOfService
        createdAt
        updatedAt
        branding {
          id
          instaQuoteMessage
          companyLogo
          companyAvatar
          tagLine
          valueAddImage
          valueAddDescription
          aboutUsAssetUrl
          aboutUsAssetType
          quoteModalImage
          createdAt
          updatedAt
          __typename
        }
        bots {
          nextToken
          __typename
        }
        programs {
          nextToken
          __typename
        }
        priceTables {
          nextToken
          __typename
        }
        __typename
      }
      rows {
        items {
          id
          priceTableRowsPriceTableId
          start
          end
          price
          units
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updatePriceTableVersioned = /* GraphQL */ `
  mutation UpdatePriceTableVersioned(
    $input: UpdatePriceTableVersionedInput!
    $condition: ModelPriceTableVersionedConditionInput
  ) {
    updatePriceTableVersioned(input: $input, condition: $condition) {
      version
      priceTableCompanyId
      id
      isDeleted
      year
      name
      prePayDiscount
      prePayCutoff
      serviceType
      conditions
      createdAt
      updatedAt
      company {
        id
        companyName
        customerName
        customerEmail
        customerPhone
        customerAddress
        customerCity
        customerState
        customerZip
        serviceTax
        maxQuotableSize
        minQuotableSize
        autoPayAvailable
        prePayAvailable
        autoPayAlternativeLabel
        autoPayPriceMethod
        autoPayMonthlyPricePeriod
        bypassPayment
        crmSolution
        crmPortalUrl
        lawnbotEmployee
        marketingSource
        callStatusCode
        callActionReason
        crmNewRecordStatusScode
        crmQuoteStatusCode
        crmSaleStatusCode
        crmCompanyId
        crmRoute
        crmDayCode
        crmHandheld
        dashboardUsername
        dashboardGreetingName
        dashboardEmailAddress
        paymentType
        termsOfService
        createdAt
        updatedAt
        branding {
          id
          instaQuoteMessage
          companyLogo
          companyAvatar
          tagLine
          valueAddImage
          valueAddDescription
          aboutUsAssetUrl
          aboutUsAssetType
          quoteModalImage
          createdAt
          updatedAt
          __typename
        }
        bots {
          nextToken
          __typename
        }
        programs {
          nextToken
          __typename
        }
        priceTables {
          nextToken
          __typename
        }
        __typename
      }
      rows {
        items {
          id
          priceTableRowsPriceTableId
          start
          end
          price
          units
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deletePriceTableVersioned = /* GraphQL */ `
  mutation DeletePriceTableVersioned(
    $input: DeletePriceTableVersionedInput!
    $condition: ModelPriceTableVersionedConditionInput
  ) {
    deletePriceTableVersioned(input: $input, condition: $condition) {
      version
      priceTableCompanyId
      id
      isDeleted
      year
      name
      prePayDiscount
      prePayCutoff
      serviceType
      conditions
      createdAt
      updatedAt
      company {
        id
        companyName
        customerName
        customerEmail
        customerPhone
        customerAddress
        customerCity
        customerState
        customerZip
        serviceTax
        maxQuotableSize
        minQuotableSize
        autoPayAvailable
        prePayAvailable
        autoPayAlternativeLabel
        autoPayPriceMethod
        autoPayMonthlyPricePeriod
        bypassPayment
        crmSolution
        crmPortalUrl
        lawnbotEmployee
        marketingSource
        callStatusCode
        callActionReason
        crmNewRecordStatusScode
        crmQuoteStatusCode
        crmSaleStatusCode
        crmCompanyId
        crmRoute
        crmDayCode
        crmHandheld
        dashboardUsername
        dashboardGreetingName
        dashboardEmailAddress
        paymentType
        termsOfService
        createdAt
        updatedAt
        branding {
          id
          instaQuoteMessage
          companyLogo
          companyAvatar
          tagLine
          valueAddImage
          valueAddDescription
          aboutUsAssetUrl
          aboutUsAssetType
          quoteModalImage
          createdAt
          updatedAt
          __typename
        }
        bots {
          nextToken
          __typename
        }
        programs {
          nextToken
          __typename
        }
        priceTables {
          nextToken
          __typename
        }
        __typename
      }
      rows {
        items {
          id
          priceTableRowsPriceTableId
          start
          end
          price
          units
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
  }
`;

/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://c3luvrfqlk.execute-api.us-east-1.amazonaws.com/qa",
            "region": "us-east-1"
        },
        {
            "name": "dashboardBridgeApi",
            "endpoint": "https://fucurbr75d.execute-api.us-east-1.amazonaws.com/qa",
            "region": "us-east-1"
        },
        {
            "name": "integrationapi",
            "endpoint": "https://13vuu3ffl4.execute-api.us-east-1.amazonaws.com/qa",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://4e22rlhy5zgkzpfgueera4hp5y.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-ceuy3ltxhze6dm5rknmzedsup4",
    "aws_cognito_identity_pool_id": "us-east-1:2641a114-3fdb-4b9d-981a-1e34a39fd647",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_8QEJ7Cikb",
    "aws_user_pools_web_client_id": "u9hfu5vulgs9olkcd7qef0jc4",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "lawnbotreactbuilder-20200113112938-hostingbucket-qa",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d25sh86n91blkk.cloudfront.net",
    "aws_user_files_s3_bucket": "lawnbotcompanystorage160147-qa",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
